import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EmailField,
  FunctionField,
  EditButton,
  ShowButton,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      label="Buscar por nombre"
      source="firstName"
      resettable
      alwaysOn
    />
    <TextInput source="documentNumber" resettable alwaysOn />
    <TextInput source="email" resettable />
    <TextInput source="phoneNumber" resettable />
  </Filter>
);

const PersonList = (props) => (
  <List sort={{ field: "id", order: "DESC" }} filters={<Filters />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        label="Nombre completo"
        sortBy="id"
        render={(record) => `${record.firstName} ${record.fatherLastName}`}
      />
      <TextField source="documentNumber" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="cellPhone" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default PersonList;

import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import customRoutes from "./customRoutes";
import spanishMessages from "./i18n/es";
import { dataProvider, authProvider } from "./Providers";
import {
  academicLevels,
  bindingCompanies,
  businessRelationshipTypes,
  businessTypes,
  cities,
  comercialActivities,
  contributionAmounts,
  countries,
  dashboard,
  distributionChannels,
  documentTypes,
  economicSectors,
  guaranteeTypes,
  legalPeople,
  maritalStatuses,
  paymentMethods,
  people,
  personRelationshipTypes,
  personRoles,
  personTypes,
  products,
  professions,
  propertyTypes,
  provinces,
  publicPositions,
  referenceTypes,
  riskLevels,
  societyTypes,
  taxRegimes,
  trustDocuments,
  trusts,
  trustTypes,
  trustParts,
} from "./Views";

import MyLayout from "./Layout/MyLayout";
import { theme } from "./theme";

const messages = {
  es: { ...spanishMessages },
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "es");

const App = () => (
  <Admin
    authProvider={authProvider}
    customRoutes={customRoutes}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    dashboard={dashboard}
    theme={theme}
    layout={MyLayout}
  >
    <Resource name="academic-levels" {...academicLevels} />
    <Resource name="business-types" {...businessTypes} />
    <Resource name="distribution-channels" {...distributionChannels} />
    <Resource name="document-types" {...documentTypes} />
    <Resource name="economic-sectors" {...economicSectors} />
    <Resource name="marital-statuses" {...maritalStatuses} />
    <Resource name="person-company-bindings" {...bindingCompanies} />
    <Resource name="person-relationship-types" {...personRelationshipTypes} />
    <Resource name="person-types" {...personTypes} />
    <Resource name="professions" {...professions} />
    <Resource name="property-types" {...propertyTypes} />
    <Resource name="reference-types" {...referenceTypes} />
    <Resource name="risk-levels" {...riskLevels} />
    <Resource
      name="business-relationship-types"
      {...businessRelationshipTypes}
    />
    <Resource name="academic-levels" {...academicLevels} />
    <Resource name="business-types" {...businessTypes} />
    <Resource name="cities" {...cities} />
    <Resource name="comercial-activities" {...comercialActivities} />
    <Resource name="contribution-amounts" {...contributionAmounts} />
    <Resource name="countries" {...countries} />
    <Resource name="guarantee-types" {...guaranteeTypes} />
    <Resource name="legal-people" {...legalPeople} />
    <Resource name="payment-methods" {...paymentMethods} />
    <Resource name="people" {...people} />
    <Resource name="person-fatca" />
    <Resource name="person-roles" {...personRoles} />
    <Resource name="products" {...products} />
    <Resource name="provinces" {...provinces} />
    <Resource name="public-positions" {...publicPositions} />
    <Resource name="society-types" {...societyTypes} />
    <Resource name="tax-regimes" {...taxRegimes} />
    <Resource name="trust-types" {...trustTypes} />
    <Resource name="trust-documents" {...trustDocuments} />
    <Resource name="trust-document-types" />
    <Resource name="trust-parts" {...trustParts} />
    <Resource name="trusts" {...trusts} />
  </Admin>
);

export default App;

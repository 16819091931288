import React, { useState } from "react";
import { MenuItemLink, DashboardMenuItem, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsIcon from "@material-ui/icons/Settings";
import BallotIcon from "@material-ui/icons/Ballot";

import SubMenu from "./SubMenu";
import { legalPeople, people } from "../Views";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  isActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderLeft: `solid ${theme.palette.primary.main} 3px`,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
}));

const MyMenu = ({ onMenuClick, logout, hasDashboard, dense }) => {
  const [state, setState] = useState({
    config: true,
    forms: true,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useStyles();
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <div className={classes.root}>
        <nav>
          {hasDashboard && (
            <DashboardMenuItem
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={false}
            />
          )}
          <SubMenu
            handleToggle={() => handleToggle("forms")}
            isOpen={state.forms}
            sidebarIsOpen={open}
            name="menu.forms"
            icon={<ListAltIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/people`}
              primaryText={translate("resources.people.name", {
                smart_count: 2,
              })}
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<people.icon />}
            />
            <MenuItemLink
              to={`/legal-people`}
              button
              primaryText={translate("resources.legal-people.name", {
                smart_count: 2,
              })}
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<legalPeople.icon />}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("config")}
            isOpen={state.config}
            sidebarIsOpen={open}
            name="menu.config"
            icon={<SettingsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/settings/maintenances`}
              primaryText={translate("menu.routes.maintenances", {
                smart_count: 2,
              })}
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<BallotIcon />}
            />
          </SubMenu>
        </nav>
      </div>
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(MyMenu);

import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import ComercialActivityCreate from "./ComercialActivityCreate";
import ComercialActivityEdit from "./ComercialActivityEdit";

import { Roles } from "../../utils/enums";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="Buscar por nombre" source="name" resettable alwaysOn />
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    {permissions === Roles.admin && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

const ComercialActivityList = ({ permissions, ...props }) => {
  const classes = FormGeneral();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/comercial-activities");
  }, [history]);

  return (
    <Fragment>
      <List
        filters={<Filters />}
        actions={<Actions permissions={permissions} />}
        bulkActionButtons={permissions === Roles.admin ? undefined : false}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField
            source="riskLevelId"
            reference="risk-levels"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          {permissions === Roles.admin && <EditButton />}
        </Datagrid>
      </List>
      {permissions === Roles.admin && (
        <Route path="/comercial-activities/create">
          {({ match }) => (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <ComercialActivityCreate
                className={classes.drawerContent}
                onCancel={handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
      )}
      {permissions === Roles.admin && (
        <Route path="/comercial-activities/:id">
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

            return (
              <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                {isMatch ? (
                  <ComercialActivityEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      )}
    </Fragment>
  );
};

export default ComercialActivityList;

import React from "react";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceField,
  SelectField,
  ShowController,
  ShowView,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useTranslate,
  EditButton,
} from "react-admin";
import { BoxedShowLayout } from "ra-compact-ui/dist/details";
import RaBox from "ra-compact-ui/dist/details/RaBox";

import { FormGeneral } from "../../styles/FormGeneral";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
    <EditButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

const TrustShow = (props) => {
  const translate = useTranslate();
  const classes = FormGeneral();

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          {...props}
          {...controllerProps}
          translate={null}
          title=" "
          actions={<Actions />}
        >
          <TabbedShowLayout>
            <Tab label="Fideicomisos">
              <GridShowLayout>
                <RaGrid container justify="space-between">
                  <RaGrid item md={4}>
                    <TextField emptyText="–" source="id" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <ReferenceField
                      reference="document-types"
                      emptyText="–"
                      source="documentTypeId"
                      link={false}
                    >
                      <TextField emptyText="–" source="name" />
                    </ReferenceField>
                  </RaGrid>
                  <RaGrid item md={4}>
                    <TextField emptyText="–" source="documentNumber" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <TextField emptyText="–" source="name" />
                  </RaGrid>

                  <RaGrid item md={8}>
                    <TextField emptyText="–" source="projectName" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <DateField emptyText="–" source="constitutionDate" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <NumberField emptyText="–" source="termOfTheContract" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <SelectField
                      choices={[
                        { id: "days", name: "Dias" },
                        { id: "months", name: "Meses" },
                        { id: "years", name: "Años" },
                      ]}
                      emptyText="–"
                      source="timeFrame"
                    />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <DateField
                      emptyText="–"
                      source="registrationTradeBedDate"
                    />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <ReferenceField
                      reference="products"
                      emptyText="–"
                      source="productId"
                      link={false}
                    >
                      <TextField emptyText="–" source="name" />
                    </ReferenceField>
                  </RaGrid>
                  <RaGrid item md={4}>
                    <ReferenceField
                      reference="contribution-amounts"
                      emptyText="–"
                      source="contributionAmountId"
                      link={false}
                    >
                      <TextField emptyText="–" source="amount" />
                    </ReferenceField>
                  </RaGrid>
                  <RaGrid item md={4}>
                    <TextField emptyText="–" source="object" />
                  </RaGrid>
                </RaGrid>
              </GridShowLayout>
            </Tab>
            <Tab label="Partes de fideicomisos" path="trustPartDetails">
              <BoxedShowLayout>
                <RaBox flex="0 0 100%" display="flex" mt="20px">
                  <ArrayField
                    addLabel={false}
                    source="trustPartsDetails"
                    className={classes.width100}
                  >
                    <Datagrid>
                      <ReferenceField
                        source="trustPartId"
                        reference="trust-parts"
                        link={false}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <ReferenceField
                        source="personTypeId"
                        reference="person-types"
                        link={false}
                      >
                        <TextField source="name" />
                      </ReferenceField>

                      <ReferenceField
                        source="personId"
                        reference="people"
                        label={translate("resources.trusts.fields.fullName")}
                        link={false}
                      >
                        <FunctionField
                          render={(record) => {
                            return (
                              record &&
                              `${record.firstName || ""} ${
                                record.secondName || ""
                              } ${record.fatherLastName || ""} ${
                                record.motherLastName || ""
                              }`
                            );
                          }}
                        />
                      </ReferenceField>
                      <ReferenceField
                        source="legalPersonId"
                        reference="legal-people"
                        link={false}
                        label={translate(
                          "resources.trusts.fields.businessName"
                        )}
                      >
                        <FunctionField
                          render={(record) => {
                            return record && `${record.businessName || ""}`;
                          }}
                        />
                      </ReferenceField>
                    </Datagrid>
                  </ArrayField>
                </RaBox>
              </BoxedShowLayout>
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default TrustShow;

import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import ShowTitle from "../../../components/ShowTitle";
import {
  DatagridDateField,
  DatagridReferenceTextField,
  DatagridTextField,
} from "../../../components";

const MultipleNationalitiesIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Nacionalidades adicionales" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="documentTypeId"
              reference="document-types"
              link={false}
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="documentNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridDateField
              resource="people"
              source="documentExpirationDate"
              id={id}
              data={data}
              emptyText="–"
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

MultipleNationalitiesIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default MultipleNationalitiesIterator;

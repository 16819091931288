import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import {
  DatagridTextField,
  DatagridReferenceTextField,
  DatagridFunctionField,
} from "../../../components";

import ShowTitle from "../../../components/ShowTitle";

const PersonPropertiesIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Propiedades personales" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="propertyTypeId"
              reference="property-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="documentTypeId"
              reference="document-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="documentNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={12}>
            <DatagridTextField
              resource="people"
              source="description"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={6}>
            <DatagridTextField
              resource="people"
              source="appraisedValue"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={6}>
            <DatagridFunctionField
              resource="people"
              source="legalOpposition"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={12}>
            <DatagridTextField
              resource="people"
              source="address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="countryId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="provinceId"
              reference="provinces"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="cityId"
              reference="cities"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

PersonPropertiesIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default PersonPropertiesIterator;

import React from "react";
import { TextInput, required, minLength, maxLength } from "react-admin";

const PhoneNumberInput = ({ isRequired, ...props }) => {
  return (
    <TextInput
      resettable={true}
      placeholder="000-000-0000"
      validate={
        isRequired
          ? [required(), minLength(10), maxLength(11)]
          : [minLength(10), maxLength(11)]
      }
      {...props}
    />
  );
};

export default PhoneNumberInput;

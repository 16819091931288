import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import DatagridFunctionField from "../../../components/DatagridFunctionField";
import DatagridReferenceTextField from "../../../components/DatagridReferenceTextField";
import DatagridTextField from "../../../components/DatagridTextField";
import DatagridDateField from "../../../components/DatagridDateField";

import ShowTitle from "../../../components/ShowTitle";

const MainShareholders = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Accionistas" />
      </RaGrid>

      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.comercialActivityId"
              reference="comercial-activities"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="sharePercentage"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="positionName"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.firstName"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.fatherLastName"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.sex"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.maritalStatusId"
              reference="marital-statuses"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.academicLevelId"
              reference="academic-levels"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.phoneNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.otherPhone"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={12}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.email"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.countryId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.provinceId"
              reference="provinces"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.cityId"
              reference="cities"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.residenceCountryId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={8}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={12}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.postalCode"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={12}>
            <ShowTitle variant="h6" title="Nacionalidad" />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.documentTypeId"
              reference="document-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.documentNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.documentIssuingCountry"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridDateField
              resource="legal-people"
              emptyText="–"
              source="person.documentExpirationDate"
              id={id}
              data={data}
            />
          </RaGrid>

          <RaGrid item xs={4}>
            <DatagridDateField
              resource="legal-people"
              emptyText="–"
              source="person.birthday"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.birthCountryId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.nationalityId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record["birthUsa"] ? "Sí" : "No")}
              source="person.personFatca[0].birthUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record.residesUsa ? "Sí" : "No")}
              source="person.personFatca[0].residesUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record.nationalityUsa ? "Sí" : "No")}
              source="person.personFatca[0].nationalityUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.personFatca[0].greenCardNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              label="Document"
              source="person.personFatca[0].documentTypeId"
              reference="document-types"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.personFatca[0].documentNumber"
              id={id}
              data={data}
            />
          </RaGrid>

          <RaGrid item xs={3}>
            <DatagridDateField
              resource="legal-people"
              emptyText="–"
              source="person.personFatca[0].documentExpirationDate"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.personFatca[0].phoneNumber"
              id={id}
              data={data}
            />
          </RaGrid>

          <RaGrid item xs={6}>
            <DatagridTextField
              resource="legal-people"
              emptyText="–"
              source="person.personFatca[0].address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.personFatca[0].provinceId"
              reference="provinces"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="legal-people"
              source="person.personFatca[0].cityId"
              reference="cities"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record.foundsTransfers ? "Sí" : "No")}
              source="person.personFatca[0].foundsTransfers"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record.representAnotherPerson ? "Sí" : "No")}
              source="person.personFatca[0].representAnotherPerson"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="legal-people"
              render={(record) => (record.bankAccount ? "Sí" : "No")}
              source="person.personFatca[0].bankAccount"
              id={id}
              data={data}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

MainShareholders.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default MainShareholders;

import { makeStyles } from "@material-ui/core";

const PrintShow = makeStyles((theme) => ({
  root: {
    "@media all": {
      "& .MuiFormLabel-root": {
        textTransform: "uppercase",
        color: "#888",
        letterSpacing: "0.75px",
        fontSize: "11px",
      },
      "& .MuiTypography-root": {
        pageBreakInside: "avoid",
        breakInside: "avoid",
      },
      "& .MuiTypography-body2": {
        fontSize: "13px",
        color: "#333",
      },
      "& .MuiGrid-item": {
        overflow: "hidden",
      },
      "&  .MuiGrid-item > .ra-field": {
        height: "100%",
        overflow: "hidden",
        " & .MuiFormControl-root": {
          boxSizing: "border-box",
          borderTop: "1px solid #333",
          borderBottom: "1px solid #333",
          borderLeft: "1px solid #ccc",
          borderRight: "1px solid #ccc",
          height: "100%",
          width: "100%",
          margin: 0,
          padding: theme.spacing(1),
          overflow: "hidden",
          "& > div": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      "'@page'": {
        "@bottom-left": {
          content: "Hola",
        },
      },
    },
  },
  signature: {
    height: 60,
  },
  onlyPrint: {
    "@media screen": {
      display: "none",
    },
  },
  printButton: {
    "@media print": {
      display: "none",
    },
    paddingBottom: "8px",
  },
}));

export { PrintShow };

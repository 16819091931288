import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    color: "red",
    fontSize: "12px",
    fontStyle: "italic",
    marginTop: "-1rem",
    width: "auto",
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>
        El País seleccionado pertenece a la lista de países no cooperantes según
        el Grupo de Acción Financiera Internacional (GAFI), por políticas de
        Cumplimiento no permite vincularse.
      </p>
    </div>
  );
};

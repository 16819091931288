import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import {
  DatagridTextField,
  DatagridReferenceTextField,
  DatagridFunctionField,
} from "../../../components";

import ShowTitle from "../../../components/ShowTitle";

const TransactionalInfoIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Información Transaccional" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="provinceId"
              reference="provinces"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="cityId"
              reference="cities"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="frequency"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="income"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="distributionChannelId"
              reference="distribution-channels"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="personCompanyBindingId"
              reference="person-company-bindings"
              textSource="businessName"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="trustId"
              reference="trusts"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="productId"
              reference="products"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="paymentMethodId"
              reference="payment-methods"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="purposeTransaction"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="sourceFunds"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="startingAmount"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="estimatedMonthlyAmount"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              source="transferAbroad"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="transferAbroadCountryId"
              reference="countries"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>

          <RaGrid item xs={12}>
            <DatagridTextField
              resource="people"
              source="address"
              id={id}
              data={data}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

TransactionalInfoIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default TransactionalInfoIterator;

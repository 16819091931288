import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Hidden } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";

import setupAnalytics from "../../assets/icons/setup-analytics.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.common.white,
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  image: {
    width: "100%",
    maxHeight: 300,
  },
}));

const getGreeting = () => {
  let message = "";
  const today = new Date();
  const curHr = today.getHours();

  switch (true) {
    case curHr >= 0 && curHr < 6:
      message = "Qué haces tan temprano?";
      break;
    case curHr >= 6 && curHr <= 12:
      message = "Buenos Días";
      break;
    case curHr >= 12 && curHr < 17:
      message = "Buenas Tardes";
      break;
    case curHr >= 17 && curHr < 24:
      message = "Buenas Noches";
      break;
    default:
      break;
  }

  return message;
};

function Header({ className, userName, ...rest }) {
  const classes = useStyles();
  const fullName = localStorage.getItem("fullName") || "(Visitante)";

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Dashboard
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            {getGreeting()}, {fullName}.
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Esto es lo que está sucediendo en el día de hoy
          </Typography>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
          >
            <BarChartIcon className={classes.barChartIcon} />
            Ver resumen
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            <img alt="Cover" className={classes.image} src={setupAnalytics} />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;

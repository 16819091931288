import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import ShowTitle from "../../../components/ShowTitle";
import {
  DatagridDateField,
  DatagridFunctionField,
  DatagridReferenceTextField,
  DatagridTextField,
} from "../../../components";

const ClientJobsIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Empleos" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="people"
              source="economicSectorId"
              reference="economic-sectors"
              basePath={basePath}
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="people"
              source="companyName"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="people"
              source="owner"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="people"
              source="positionName"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridDateField
              resource="people"
              source="effectiveDate"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridDateField
              resource="people"
              source="expirationDate"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="phoneNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="cellPhone"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="otherPhone"
              id={id}
              data={data}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

ClientJobsIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default ClientJobsIterator;

import React, { Fragment, useCallback } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  email,
  FormDataConsumer,
  FormTab,
  ListButton,
  maxLength,
  minLength,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { useForm } from "react-final-form";
import classnames from "classnames";

import {
  CustomSelectInput,
  PhoneNumberInput,
  FormTitle,
  CountrySelect,
} from "../../components";
import {
  noFutureDate,
  getRiskLevel,
  isRolCreditor,
} from "../../utils/validations";
import { PersonTypes, CountryRiskLevel } from "../../utils/enums";
import { useSelectDataProvider } from "../../hooks";
import { LocationSelect } from "../../components/LocationSelect";
import { FormGeneral } from "../../styles/FormGeneral";
import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";
import { trustOptionRenderer } from "../../utils/optionRederers";

const SaveButtonWithFatca = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    const { personFatca } = form.getState().values;

    form.getState().invalid === false && form.change("personFatca", null);
    form.getState().invalid === false &&
      form.change("personFatca", [personFatca]);

    handleSubmitWithRedirect("list");
    // eslint-disable-next-line
  }, [handleSubmitWithRedirect]);

  return (
    <Fragment>
      <SaveButton handleSubmitWithRedirect={handleClick} {...props} />
    </Fragment>
  );
};

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const PeopleCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButtonWithFatca label="ra.action.save" />
  </Toolbar>
);

const personDefaultValues = {
  personTypeId: PersonTypes.physical,
};

const PersonCreate = (props) => {
  const classes = FormGeneral();
  const countries = useSelectDataProvider("countries");
  const documentTypes = useSelectDataProvider("document-types");
  const translate = useTranslate();

  const getUSAId = () => {
    return (
      countries.find((country) => country?.name === "Estados Unidos")?.id || 1
    );
  };

  return (
    <Create title=" " actions={<Actions />} {...props}>
      <TabbedForm
        toolbar={<PeopleCreateToolbar />}
        redirect={props.basePath}
        variant="outlined"
        initialValues={personDefaultValues}
      >
        <FormTab label="General" contentClassName={classes.root}>
          <FormTitle
            title="Información personal"
            formClassName={classes.width100}
          />
          <TextInput
            source="firstName"
            formClassName={classes.width25}
            fullWidth
            validate={[required(), maxLength(50)]}
          />
          <TextInput
            source="secondName"
            formClassName={classes.width25}
            fullWidth
            validate={[maxLength(50)]}
          />
          <TextInput
            source="fatherLastName"
            formClassName={classes.width25}
            fullWidth
            validate={[required(), maxLength(50)]}
          />
          <TextInput
            source="motherLastName"
            formClassName={classes.width25}
            fullWidth
            validate={[required(), maxLength(50)]}
          />
          <ReferenceArrayInput
            formClassName={classes.width25}
            source="roles"
            reference="person-roles"
            fullWidth
            validate={required()}
          >
            <SelectArrayInput />
          </ReferenceArrayInput>
          <SelectInput
            source="sex"
            formClassName={classes.width25}
            fullWidth
            choices={[
              { id: "M", name: "Masculino" },
              { id: "F", name: "Femenino" },
            ]}
            validate={required()}
          />
          <ReferenceInput
            source="academicLevelId"
            formClassName={classes.width25}
            fullWidth
            reference="academic-levels"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            source="professionId"
            formClassName={classes.width25}
            fullWidth
            reference="professions"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            source="maritalStatusId"
            formClassName={classes.width50}
            fullWidth
            reference="marital-statuses"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            source="comercialActivityId"
            formClassName={classes.width50}
            fullWidth
            reference="comercial-activities"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <FormTitle title="Contacto" formClassName={classes.width100} />
          <PhoneNumberInput
            formClassName={classes.width33}
            fullWidth
            source="phoneNumber"
          />
          <PhoneNumberInput
            formClassName={classes.width33}
            fullWidth
            source="cellPhone"
          />
          <PhoneNumberInput
            formClassName={classes.width33}
            fullWidth
            source="otherPhone"
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="email"
            type="email"
            validate={email()}
          />
          <TextInput
            source="address"
            formClassName={classes.width33}
            fullWidth
            validate={maxLength(250)}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="postalCode"
            validate={maxLength(20)}
          />
          <LocationSelect
            formClassName={classes.width100}
            countries={countries}
          />
          <CountrySelect
            source="residenceCountryId"
            choices={countries}
            formClassName={classes.width33}
            fullWidth
            validate={required()}
          />
          <FormTitle title="Nacionalidad" formClassName={classes.width100} />
          <CustomSelectInput
            formClassName={classes.width33}
            fullWidth
            source="documentTypeId"
            choices={documentTypes}
            validate={required()}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="documentNumber"
            validate={[required(), minLength(9), maxLength(20)]}
          />
          <CountrySelect
            formClassName={classes.width33}
            fullWidth
            source="documentIssuingCountry"
            choices={countries}
            validate={required()}
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="documentExpirationDate"
            validate={required()}
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="birthday"
            validate={[required(), noFutureDate]}
          />
          <CountrySelect
            formClassName={classes.width33}
            fullWidth
            source="birthCountryId"
            choices={countries}
            validate={required()}
          />
          <CountrySelect
            formClassName={classes.width33}
            fullWidth
            source="nationalityId"
            choices={countries}
            optionText="nationality"
            validate={required()}
          />
          <BooleanInput
            defaultValue={false}
            formClassName={classes.width100}
            fullWidth
            source="foreign"
          />
          <FormDataConsumer formClassName={classes.width100} fullWidth>
            {({ formData, ...rest }) =>
              formData.foreign && (
                <ArrayInput
                  {...rest}
                  className={classnames(classes.form, classes.width100)}
                  source="personMultipleNationalities"
                >
                  <SimpleFormIterator>
                    <FormTitle
                      title="Nacionalidad adicional"
                      formClassName={classes.width100}
                    />
                    <CountrySelect
                      formClassName={classes.width33}
                      fullWidth
                      source="countryId"
                      choices={countries}
                    />
                    <CustomSelectInput
                      formClassName={classes.width33}
                      fullWidth
                      source="documentTypeId"
                      choices={documentTypes}
                      validate={required()}
                    />
                    <TextInput
                      formClassName={classes.width33}
                      fullWidth
                      source="documentNumber"
                      validate={[required(), minLength(9), maxLength(20)]}
                    />
                    <DateInput
                      formClassName={classes.width33}
                      fullWidth
                      source="documentExpirationDate"
                      validate={required()}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          </FormDataConsumer>
          <FormTitle title="Fatca" formClassName={classes.width100} />
          <CustomSelectInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.documentTypeId"
            choices={documentTypes}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.documentNumber"
            validate={[minLength(9), maxLength(11)]}
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.documentExpirationDate"
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.greenCardNumber"
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.birthUsa"
            defaultValue={false}
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.residesUsa"
            defaultValue={false}
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.nationalityUsa"
            defaultValue={false}
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.foundsTransfers"
            defaultValue={false}
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.representAnotherPerson"
            defaultValue={false}
          />
          <BooleanInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.bankAccount"
            defaultValue={false}
          />
          <PhoneNumberInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.phoneNumber"
          />
          <PhoneNumberInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.cellPhone"
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="personFatca.address"
            validate={maxLength(250)}
          />
          {countries ? (
            <ReferenceInput
              formClassName={classes.width33}
              filter={{ countryId: getUSAId() }}
              fullWidth
              source="personFatca.provinceId"
              reference="provinces"
            >
              <SelectInput />
            </ReferenceInput>
          ) : (
            <SelectInput
              choices={[]}
              disabled
              source="personFatca.provinceId"
            />
          )}
          <FormDataConsumer fullWidth formClassName={classes.width33}>
            {({ formData, ...rest }) =>
              formData.personFatca.provinceId ? (
                <ReferenceAutocomplete
                  filter={{ provinceId: formData.personFatca.provinceId }}
                  reference="cities"
                  source="personFatca.cityId"
                  {...rest}
                />
              ) : (
                <SelectInput
                  {...rest}
                  choices={[]}
                  disabled
                  source="personFatca.cityId"
                />
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Empleos" path="client-jobs">
          <ArrayInput
            validate={isRolCreditor}
            source="clientJobs"
            className={classes.form}
          >
            <SimpleFormIterator>
              <FormTitle
                title="Información laboral"
                formClassName={classes.width100}
              />
              <ReferenceInput
                source="economicSectorId"
                reference="economic-sectors"
                formClassName={classes.width33}
                fullWidth
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                source="companyName"
                validate={required()}
                formClassName={classes.width33}
                fullWidth
              />
              <BooleanInput
                source="owner"
                formClassName={classes.width33}
                fullWidth
                defaultValue={false}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="positionName"
                validate={required()}
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="income"
                validate={[required()]}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="address"
                validate={[required(), maxLength(250)]}
              />
              <DateInput
                formClassName={classes.width50}
                fullWidth
                source="effectiveDate"
              />
              <DateInput
                formClassName={classes.width50}
                fullWidth
                source="expirationDate"
              />
              <FormTitle
                title="Datos de contacto laboral"
                formClassName={classes.width100}
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="phoneNumber"
                isRequired={required()}
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="cellPhone"
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="otherPhone"
              />
              <TextInput
                formClassName={classes.width50}
                fullWidth
                source="supervisor"
              />
              <PhoneNumberInput
                formClassName={classes.width50}
                fullWidth
                source="supervisorPhone"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="PEP" path="person-pep">
          <ArrayInput source="personPep" className={classes.form}>
            <SimpleFormIterator>
              <FormTitle
                title="Información de Persona Expuesta Políticamente"
                formClassName={classes.width100}
              />
              <BooleanInput
                source="publicOfficer"
                formClassName={classes.width33}
                defaultValue={false}
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="publicPositionId"
                reference="public-positions"
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="publicOffice"
              />
              <BooleanInput
                source="familyPublicOfficer"
                formClassName={classes.width33}
                defaultValue={false}
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="relationshipId"
                reference="person-relationship-types"
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="familyPublicPositionId"
                reference="public-positions"
              >
                <SelectInput />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Propiedades" path="person-properties">
          <ArrayInput className={classes.form} source="personProperties">
            <SimpleFormIterator>
              <FormTitle
                formClassName={classes.width100}
                title="Información de propiedad"
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                reference="property-types"
                source="propertyTypeId"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <CustomSelectInput
                choices={documentTypes}
                formClassName={classes.width33}
                fullWidth
                source="documentTypeId"
                validate={required()}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="documentNumber"
                validate={[required(), minLength(9), maxLength(20)]}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="description"
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="appraisedValue"
              />
              <BooleanInput
                defaultValue={false}
                formClassName={classes.width33}
                fullWidth
                source="legalOpposition"
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                multiline
                rows="4"
                source="address"
                validate={maxLength(250)}
              />
              <CountrySelect
                choices={countries}
                formClassName={classes.width33}
                fullWidth
                source="countryId"
              />
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  scopedFormData.countryId &&
                  getRiskLevel(countries, scopedFormData.countryId) !==
                    CountryRiskLevel.extreme ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ countryId: scopedFormData.countryId }}
                      reference="provinces"
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate("resources.people.fields.provinceId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate("resources.people.fields.provinceId")}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ provinceId: scopedFormData.provinceId }}
                      reference="cities"
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.people.fields.cityId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.people.fields.cityId")}
                    />
                  )
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Referencias" path="person-references">
          <ArrayInput className={classes.form} source="personReferences">
            <SimpleFormIterator>
              <FormTitle
                title="Referencia personal"
                formClassName={classes.width100}
              />
              <ReferenceInput
                source="referenceTypeId"
                reference="reference-types"
                formClassName={classes.width50}
                fullWidth
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                source="relationshipId"
                formClassName={classes.width50}
                fullWidth
                reference="person-relationship-types"
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                formClassName={classes.width50}
                fullWidth
                source="name"
              />
              <TextInput
                formClassName={classes.width50}
                fullWidth
                source="contactOfficer"
              />
              <PhoneNumberInput
                formClassName={classes.width50}
                fullWidth
                source="phoneNumber"
              />
              <TextInput
                formClassName={classes.width50}
                fullWidth
                source="address"
                validate={maxLength(250)}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="email"
                validate={email()}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="position"
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="company"
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="income"
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="otherIncome"
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="notes"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Transaccional" path="transactional-info">
          <ArrayInput className={classes.form} source="transactionalInfo">
            <SimpleFormIterator>
              <FormTitle
                title="Información transaccional"
                formClassName={classes.width100}
              />
              <ReferenceAutocomplete
                formClassName={classes.width33}
                fullWidth
                validate={required()}
                source="provinceId"
                reference="provinces"
              />
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ provinceId: scopedFormData.provinceId }}
                      reference="cities"
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  )
                }
              </FormDataConsumer>
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="frequency"
                validate={required()}
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="income"
                validate={required()}
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="distributionChannelId"
                reference="distribution-channels"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="personCompanyBindingId"
                reference="person-company-bindings"
                validate={required()}
              >
                <SelectInput optionText="businessName" />
              </ReferenceInput>
              <ReferenceAutocomplete
                formClassName={classes.width33}
                fullWidth
                reference="trusts"
                source="trustId"
                validate={required()}
                optionText={trustOptionRenderer}
                filterToQuery={(searchText) =>
                  searchText && {
                    $or: true,
                    shortName: searchText,
                    documentNumber: searchText,
                  }
                }
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="productId"
                reference="products"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="paymentMethodId"
                reference="payment-methods"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="purposeTransaction"
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="sourceFunds"
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="startingAmount"
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="estimatedMonthlyAmount"
              />
              <BooleanInput
                formClassName={classes.width33}
                fullWidth
                source="transferAbroad"
              />
              <CountrySelect
                formClassName={classes.width33}
                fullWidth
                source="transferAbroadCountryId"
                choices={countries}
              />
              <BooleanInput
                formClassName={classes.width33}
                fullWidth
                source="transferFromAbroad"
              />
              <CountrySelect
                formClassName={classes.width33}
                fullWidth
                source="transferFromCountryId"
                choices={countries}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="address"
                validate={maxLength(250)}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default PersonCreate;

import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
  ReferenceField,
} from "react-admin";

import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import ContributionAmountCreate from "./ContributionAmountCreate";
import ContributionAmountEdit from "./ContributionAmountEdit";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="ra.action.search" source="name" alwaysOn />
  </Filter>
);

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const ContributionAmountList = (props) => {
  const classes = FormGeneral();
  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push("/contribution-amounts");
  }, [history]);

  return (
    <Fragment>
      <List filters={<Filters />} actions={<Actions />} {...props}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="amount" />
          <ReferenceField
            source="riskLevelId"
            reference="risk-levels"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      </List>
      <Route path="/contribution-amounts/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <ContributionAmountCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path="/contribution-amounts/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <ContributionAmountEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default ContributionAmountList;

export const trustOptionRenderer = (choice) => {
  return `${choice.documentNumber} – ${choice.shortName}`;
};

export const fullNameOptionRendererWithDocumentNumber = (choice) => {
  return `${choice.documentNumber} – ${choice.firstName} ${choice.secondName} ${choice.fatherLastName} ${choice.motherLastName}`;
};

export const businessNameWithDocumentNumberRenderer = (choice) => {
  return `${choice.documentNumber} – ${choice.businessName}`;
};

import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import ShowTitle from "../../../components/ShowTitle";
import {
  DatagridDateField,
  DatagridTextField,
  DatagridReferenceTextField,
  DatagridFunctionField,
} from "../../../components";

const PersonFatcaIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="FATCA" />
      </RaGrid>

      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record["birthUsa"] ? "Sí" : "No")}
              source="birthUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record.residesUsa ? "Sí" : "No")}
              source="residesUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record.nationalityUsa ? "Sí" : "No")}
              source="nationalityUsa"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="greenCardNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              label="Document"
              source="documentTypeId"
              reference="document-types"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="documentNumber"
              id={id}
              data={data}
            />
          </RaGrid>

          <RaGrid item xs={4}>
            <DatagridDateField
              resource="people"
              emptyText="–"
              source="documentExpirationDate"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={6}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="phoneNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={6}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="cellPhone"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={6}>
            <DatagridTextField
              resource="people"
              emptyText="–"
              source="address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="people"
              source="provinceId"
              reference="provinces"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={3}>
            <DatagridReferenceTextField
              resource="people"
              source="cityId"
              reference="cities"
              link={false}
              emptyText="–"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record.foundsTransfers ? "Sí" : "No")}
              source="foundsTransfers"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record.representAnotherPerson ? "Sí" : "No")}
              source="representAnotherPerson"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              render={(record) => (record.bankAccount ? "Sí" : "No")}
              source="bankAccount"
              id={id}
              data={data}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

PersonFatcaIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default PersonFatcaIterator;

import React from "react";
import {
  Create,
  SimpleForm,
  TopToolbar,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const ProvinceCreate = (props) => (
  <Create title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm redirect={props.basePath} variant="outlined">
      <ReferenceAutocomplete
        fullWidth
        reference="countries"
        source="countryId"
        validate={required()}
      />
      <ReferenceInput
        fullWidth
        reference="risk-levels"
        source="riskId"
        validate={required()}
      >
        <SelectInput />
      </ReferenceInput>
      <TextInput source="name" validate={required()} fullWidth />
    </SimpleForm>
  </Create>
);

export default ProvinceCreate;

import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";

const useSelectDataProvider = (resource) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [selectData, setSelectData] = useState();

  useEffect(() => {
    dataProvider
      .getList(resource, {
        pagination: { page: 1, perPage: 250 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setSelectData(data);
      })
      .catch((error) => {
        notify(`useSelectDataProvider[${resource}]: ${error.message}`, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  return selectData;
};

export default useSelectDataProvider;

import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TopToolbar,
  CreateButton,
  ReferenceField,
  TextInput,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import ProvinceCreate from "./ProvinceCreate";
import ProvinceEdit from "./ProvinceEdit";

import { Roles } from "../../utils/enums";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="Buscar por nombre" source="name" resettable alwaysOn />
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    {permissions === Roles.admin && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

const ProvinceList = ({ permissions, ...props }) => {
  const classes = FormGeneral();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/provinces");
  }, [history]);

  return (
    <Fragment>
      <List
        filters={<Filters />}
        actions={<Actions permissions={permissions} />}
        bulkActionButtons={permissions === Roles.admin ? undefined : false}
        sort={{ field: "id", order: "DESC" }}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <ReferenceField source="countryId" reference="countries">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="riskId" reference="risk-levels">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />
          {permissions === Roles.admin && <EditButton />}
        </Datagrid>
      </List>
      {permissions === Roles.admin && (
        <Route path="/provinces/create">
          {({ match }) => (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={handleClose}
              classes={{ root: classes.drawerIndex }}
            >
              <ProvinceCreate
                className={classes.drawerContent}
                onCancel={handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
      )}
      {permissions === Roles.admin && (
        <Route path="/provinces/:id">
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

            return (
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{ root: classes.drawerIndex }}
              >
                {isMatch ? (
                  <ProvinceEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      )}
    </Fragment>
  );
};

export default ProvinceList;

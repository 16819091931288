import spanishMessages from "@blackbox-vision/ra-language-spanish";

export default {
  ...spanishMessages,
  menu: {
    config: "Ajustes",
    forms: "Crear Formulario",
    routes: { maintenances: "Mantenimientos" },
  },
  resources: {
    dashboard: {
      name: "Dashboard |||| Dashboard",
    },
    people: {
      name: "Persona |||| Personas",
      fields: {
        roles: "Roles",
        academicLevelId: "Nivel académico",
        address: "Dirección",
        birthCountryId: "Lugar de nacimiento",
        birthday: "Fecha de nacimiento",
        cellPhone: "Celular",
        cityId: "Ciudad",
        countryId: "País",
        country: "País",
        professionId: "Profesión",
        documentExpirationDate: "Expiración del documento",
        documentIssuingCountry: "País de emisión",
        documentNumber: "Documento de identidad",
        documentTypeId: "Tipo de documento",
        email: "Correo",
        firstName: "Nombre",
        comercialActivityId: "Actividad comercial",
        fatherLastName: "Apellido Paterno",
        motherLastName: "Apellido Materno",
        secondName: "Segundo Nombre",
        foreign: "¿Es extranjero?",
        maritalStatusId: "Estado civil",
        nationalityId: "Nacionalidad",
        otherPhone: "Otro contacto",
        phoneNumber: "Teléfono",
        postalCode: "Código postal",
        provinceId: "Provincia",
        residenceCountryId: "País de residencia",
        sex: "Sexo",

        // clientJobs
        clientJobs: "Trabajos",
        economicSectorId: "Sector económico",
        companyName: "Compañía",
        effectiveDate: "Fecha de entrada",
        expirationDate: "Fecha de salida",
        income: "Ingresos",
        owner: "¿Es dueño ?",
        positionName: "Posición",
        supervisor: "Supervisor",
        supervisorPhone: "Contacto supervisor",

        // personProperties
        personProperties: "Propiedades",
        propertyTypeId: "Tipo de propiedad",
        description: "Descripción",
        appraisedValue: "Valor estimado",
        legalOpposition: "¿Tiene oposición legal?",
        "personProperties[0]": {
          provinceId: "Provincia",
          cityId: "Ciudad",
          countryId: "País",
        },

        // personReferences
        personReferences: "Referencias",
        referenceTypeId: "Tipo de referencia",
        relationshipId: "Tipo de relación",
        name: "Nombre completo",
        contactOfficer: "Oficial de contacto",
        position: "Posición",
        company: "Compañía",
        otherIncome: "Otros ingresos",
        notes: "Notas",

        // personPEP
        personPep: "PEP",
        personId: "Persona",
        publicOffice: "Oficina pública",
        publicOfficer: "Oficial público",
        publicPositionId: "Cargo público",
        familyPublicOfficer: "Oficial público (Familiar)",
        familyPublicPositionId: "Cargo público (Familiar)",

        // personFatca
        personFatca: {
          personFatca: "Fatca",
          greenCardNumber: "Número de Green Card",
          birthUsa: "¿Usted nació en EUA?",
          residesUsa: "¿Reside en EUA?",
          nationalityUsa: "Es ciudadano de EUA?",
          foundsTransfers: "¿Realiza transferencia de fondos a EUA?",
          representAnotherPerson:
            "Representa usted a una persona con residencia en los EUA?",
          bankAccount: "Tiene cuentas bancarias en EUA?",
          documentTypeId: "Tipo de documento de EUA",
          documentNumber: "Documento de identidad de EUA",
          documentExpirationDate: "Fecha de expiración del documento EUA",
          phoneNumber: "Teléfono personal EUA",
          cellPhone: "Célular personal EUA",
          address: "Dirección en EUA",
          provinceId: "Provincia",
          cityId: "Ciudad",
        },
        "personFatca[0]": {
          personFatca: "Fatca",
          greenCardNumber: "Número de Green Card",
          birthUsa: "¿Usted nació en EUA?",
          residesUsa: "¿Reside en EUA?",
          nationalityUsa: "Es ciudadano de EUA?",
          foundsTransfers: "¿Realiza transferencia de fondos a EUA?",
          representAnotherPerson:
            "Representa usted a una persona con residencia en los EUA?",
          bankAccount: "Tiene cuentas bancarias en EUA?",
          documentTypeId: "Tipo de documento de EUA",
          documentNumber: "Documento de identidad de EUA",
          documentExpirationDate: "Fecha de expiración del documento EUA",
          phoneNumber: "Teléfono personal EUA",
          cellPhone: "Célular personal EUA",
          address: "Dirección en EUA",
          provinceId: "Provincia",
          cityId: "Ciudad",
        },
        greenCardNumber: "Número de Green Card",
        birthUsa: "¿Usted nació en EUA?",
        residesUsa: "¿Reside en EUA?",
        nationalityUsa: "Es ciudadano de EUA?",
        foundsTransfers: "¿Realiza transferencia de fondos a EUA?",
        representAnotherPerson:
          "Representa usted a una persona con residencia en los EUA?",
        bankAccount: "Tiene cuentas bancarias en EUA?",

        // transactionalInfo
        distributionChannelId: "Canal de Vinculación",
        estimatedMonthlyAmount: "Promedio mensual estimado",
        frequency: "Frecuencia Mensual Estimada de Transacciones",
        paymentMethodId: "Método de pago",
        personCompanyBindingId: "Empresa Vinculante",
        productId: "Producto",
        purposeTransaction: "Objetivo transaccional",
        sourceFunds: "Origen de los fondos",
        startingAmount: "Monto depósito inicial",
        transactionalInfo: "Información transaccional",
        transferAbroad: "¿Realizara transferencia al exterior?",
        transferAbroadCountryId: "¿A qué país?",
        transferFromAbroad: "¿Recibirá transferencias del exterior?",
        transferFromCountryId: "¿Desde qué país?",
        trustId: "Fideicomiso",
      },
    },
    "legal-people": {
      name: "Persona Jurídica |||| Personas Jurídicas",
      fields: {
        roles: "Roles",
        personTypeId: "Tipo de persona",
        businessName: "Razón social",
        businessTypeId: "Tipo de empresa",
        cityId: "Ciudad",
        commercialRegister: "Registro mercantíl",
        constitutionCountryId: "País de constitución",
        constitutionDate: "Fecha de constitución",
        countryId: "País",
        documentNumber: "Número de documento",
        documentTypeId: "Tipo de documento",
        email: "Correo electrónico",
        legalEvaluationDate: "Fecha de evaluación legal",
        obligedSubject: "¿Sujeto obligado?",
        otherPhoneNumber: "Teléfono 2",
        phoneNumber: "Teléfono 1",
        postalCode: "Código postal",
        provinceId: "Provincia",
        registerExpirationDate: "Fecha de vencimiento de Reg. Merc.",
        registerIssueDate: "Fecha de emisión de Reg. Merc.",
        societyTypeId: "Tipo de sociedad",
        subsidiaryCompany: "Compañía subsidiaria",
        taxRegimeId: "Régimen fiscal",
        tradename: "Nombre del negocio",
        transferAbroad: "¿Realizara transferencia al exterior?",
        transferAbroadCountryId: "¿A qué país?",
        transferFromAbroad: "¿Recibirá transferencias del exterior?",
        transferFromCountryId: "¿Desde qué país?",
        webPage: "Página web",
        documentStatus: "Estado de Reg. Merc.",
        operationYears: "Tiempo de operación (Años)",
        // legalPersonReferences

        referenceTypeId: "Tipo de referencia",
        name: "Nombre",
        address: "Dirección",
        timeAsCustomerYears: "Tiempo como cliente",

        // legalPersonTransactionalInfos

        distributionChannelId: "Canal de Vinculación",
        estimatedMonthlyAmount: "Promedio mensual estimado",
        frequency: "Frecuencia Mensual Estimada de Transacciones",
        paymentMethodId: "Método de pago",
        personCompanyBindingId: "Empresa Vinculante",
        productId: "Producto",
        purposeTransaction: "Objetivo transaccional",
        sourceFunds: "Origen de los fondos",
        startingAmount: "Monto depósito inicial",
        transactionalInfo: "Información transaccional",
        income: "Ingresos",
        trustId: "Fideicomiso",
        "legalPersonTransactionalInfos[0]": {
          cityId: "Ciudad",
        },
        // mainBusinessRelationships

        businessRelationshipTypeId: "Tipo de relación de negocios",
        managerName: "Nombre del gerente",
        managerPhoneNumber: "Número de teléfono del gerente",

        // accionistas

        sharePercentage: "Porcentaje accionario",
        positionName: "Posición",
        nameOrDocument: "Nombre o documento",
        person: {
          academicLevelId: "Nivel académico",
          address: "Dirección",
          birthCountryId: "Lugar de nacimiento",
          birthday: "Fecha de nacimiento",
          cellPhone: "Celular",
          cityId: "Ciudad",
          countryId: "País",
          professionId: "Profesión",
          country: "País",
          documentExpirationDate: "Fecha vencimiento del documento",
          documentIssuingCountry: "País de emisión",
          documentNumber: "Documento de identidad",
          documentTypeId: "Tipo de documento",
          email: "Correo",
          foreign: "¿Es extranjero?",
          firstName: "Nombre",
          fatherLastName: "Apellido Paterno",
          motherLastName: "Apellido Materno",
          secondName: "Segundo Nombre",
          maritalStatusId: "Estado civil",
          nationalityId: "Nacionalidad",
          otherPhone: "Otro contacto",
          phoneNumber: "Teléfono",
          postalCode: "Código postal",
          provinceId: "Provincia",
          residenceCountryId: "País de residencia",
          sex: "Sexo",
          comercialActivityId: "Actividad comercial",
          personFatca: {
            address: "Dirección en EUA",
            bankAccount: "Tiene cuentas bancarias en EUA?",
            birthUsa: "¿Usted nació en EUA?",
            documentExpirationDate: "Fecha de expiración del documento EUA",
            documentNumber: "Documento de identidad de EUA",
            documentTypeId: "Tipo de documento de EUA",
            foundsTransfers: "¿Realiza transferencia de fondos a EUA?",
            greenCardNumber: "Número de Green Card",
            nationalityUsa: "Es ciudadano de EUA?",
            phoneNumber: "Teléfono personal EUA",
            provinceId: "Provincia",
            representAnotherPerson:
              "Representa usted a una persona con residencia en los EUA?",
            residesUsa: "¿Reside en EUA?",
          },
          "personFatca[0]": {
            address: "Dirección en EUA",
            bankAccount: "Tiene cuentas bancarias en EUA?",
            birthUsa: "¿Usted nació en EUA?",
            documentExpirationDate: "Fecha de expiración del documento EUA",
            documentNumber: "Documento de identidad de EUA",
            documentTypeId: "Tipo de documento de EUA",
            foundsTransfers: "¿Realiza transferencia de fondos a EUA?",
            greenCardNumber: "Número de Green Card",
            nationalityUsa: "Es ciudadano de EUA?",
            phoneNumber: "Teléfono personal EUA",
            provinceId: "Provincia",
            representAnotherPerson:
              "Representa usted a una persona con residencia en los EUA?",
            residesUsa: "¿Reside en EUA?",
          },
        },

        // branch offices
        branchOfficeName: "Nombre de la sucursal",
        branchManagerName: "Nombre del gerente",
        otherPhone: "Otro teléfono",
      },
    },
    "academic-levels": {
      name: "Nivel académico |||| Niveles académicos",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "person-relationship-types": {
      name: "Tipo de relación |||| Tipos de relaciones",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "reference-types": {
      name: "Tipo de referencia |||| Tipos de referencias",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "property-types": {
      name: "Tipo de propiedad |||| Tipos de propiedades",
      fields: {
        name: "Nombre del tipo de propiedad",
        description: "Descripción",
      },
    },
    "document-types": {
      name: "Tipo de documento |||| Tipos de documentos",
      fields: {
        name: "Nombre",
        description: "Descripción",
        riskLevelId: "Nivel de riesgo",
      },
    },
    "person-types": {
      name: "Tipo de persona |||| Tipos de personas",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "person-references": {
      name: "Referencia |||| Referencias",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "guarantee-types": {
      name: "Tipo de garantía |||| Tipos de garantías",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "payment-methods": {
      name: "Método de pago |||| Métodos de pagos",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "marital-statuses": {
      name: "Estado civíl |||| Estados civiles",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "economic-sectors": {
      name: "Sector económico |||| Sectores económicos",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "person-roles": {
      name: "Rol de persona |||| Roles de persona",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    countries: {
      name: "País |||| Países",
      fields: {
        name: "Nombre",
        nationality: "Nacionalidad",
        riskId: "Nivel de riesgo",
        isoId: "Código ISO",
      },
    },
    "comercial-activities": {
      name: "Actividad Comercial |||| Actividad Comerciales",
      fields: {
        name: "Nombre",
        riskLevelId: "Nivel de riesgo",
      },
    },
    cities: {
      name: "Ciudad |||| Ciudades",
      fields: {
        provinceId: "Provincia",
        countryId: "País",
        name: "Nombre",
      },
    },
    provinces: {
      name: "Provincia |||| Provincias",
      fields: {
        name: "Nombre",
        countryId: "País",
        riskId: "Nivel de riesgo",
      },
    },
    "public-positions": {
      name: "Posición Publica |||| Posiciones Publicas",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "society-types": {
      name: "Tipo de sociedad |||| Tipos de sociedad",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "tax-regimes": {
      name: "Regimen tributario |||| Regímenes tributarios",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "business-types": {
      name: "Tipo de empresa |||| Tipos de empresas",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "business-relationship-types": {
      name: "Relación comercial |||| Relaciones comerciales",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    trusts: {
      name: "Fideicomiso |||| Fideicomisos",
      fields: {
        documentNumber: "Documento de identidad",
        documentTypeId: "Tipo de documento",
        name: "Nombre Fideicomiso",
        shortName: "Nombre Corto Fideicomiso",
        trustTypeId: "Tipo de Fideicomiso",
        projectName: "Nombre del proyecto",
        constitutionDate: "Fecha de Constitución",
        termOfTheContract: "Tiempo Vigencia del Contrato",
        timeFrame: "Periodo de Tiempo",
        expirationDate: "Fecha de Vencimiento",
        registrationTradeBedDate: "Fecha de registro en la CC y P",
        registrationTradeBedDateNumber: "Número de Registro en CC y P",
        productId: "Producto",
        contributionAmountId: "Monto Aporte a Fideicomiso",
        object: "Objeto",
        trustPartsDetails: "Detalles de partes de fiduciaria",
        trustPartId: "Parte de fiduciaria",
        personTypeId: "Tipo de persona",
        nameOrDocument: "Nombre o documento",
        businessName: "Nombre del negocio",
        fullName: "Nombre completo",
      },
    },
    products: {
      name: "Producto |||| Productos",
      fields: {
        name: "Nombre",
        riskLevelId: "Nivel de riesgo",
      },
    },
    "risk-levels": {
      name: "Nivel de riesgo |||| Niveles de riegos",
      fields: {
        name: "Nombre",
      },
    },
    professions: {
      name: "Profesión |||| Profesiones",
      fields: {
        name: "Nombre",
        riskLevelId: "Nivel de riesgo",
      },
    },
    "person-company-bindings": {
      name: "Persona/Empresa Vinculante |||| Persona/Empresas Vinculantes",
      fields: {
        documentNumber: "Número de Documento",
        businessName: "Nombre/Razón Social",
        trustId: "Nombre del Fideicomiso",
      },
    },
    "distribution-channels": {
      name: "Canal De Distribución |||| Canales De Distribuciónes",
      fields: {
        name: "Nombre",
        riskLevelId: "Nivel de riesgo",
      },
    },
    "trust-types": {
      name: "Tipo de fideicomiso |||| Tipos de fideicomisos",
      fields: {
        name: "Nombre",
      },
    },
    "trust-documents": {
      name: "Documento de confianza |||| Documentos de confianza",
      fields: {
        documentTypeId: "Tipo de documentos",
        name: "Nombre",
      },
    },
    "contribution-amounts": {
      name: "Monto Aporte |||| Montos Aportes",
      fields: {
        amount: "Monto Aporte a Fideicomiso",
        riskLevelId: "Nivel de riesgo",
      },
    },
    "trust-parts": {
      name: "Parte de Fideicomiso |||| Partes de Fideicomisos",
      fields: {
        name: "Partes",
      },
    },
  },
};

import React from "react";
import { TextField, ReferenceField, Labeled } from "react-admin";

const DatagridReferenceTextField = ({
  id,
  data,
  source,
  reference,
  basePath,
  textSource = "name",
  resource,
  ...props
}) => {
  return data[id][source] !== null ? (
    <ReferenceField
      source={source}
      reference={reference}
      record={data[id]}
      {...props}
      basePath={basePath}
      link={false}
    >
      <Labeled label={`resources.${resource}.fields.${source}`}>
        <TextField emptyText="–" source={textSource} />
      </Labeled>
    </ReferenceField>
  ) : (
    <Labeled label={`resources.${resource}.fields.${source}`}>
      <TextField emptyText="–" />
    </Labeled>
  );
};

export default DatagridReferenceTextField;

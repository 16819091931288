import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import {
  Container,
  Divider,
  Grid,
  List,
  Paper,
  Typography,
} from "@material-ui/core";

import ListItemLink from "../../components/ListItemLink";
import {
  academicLevels,
  bindingCompanies,
  businessRelationshipTypes,
  businessTypes,
  cities,
  comercialActivities,
  contributionAmounts,
  countries,
  distributionChannels,
  documentTypes,
  economicSectors,
  guaranteeTypes,
  maritalStatuses,
  paymentMethods,
  personRelationshipTypes,
  personRoles,
  personTypes,
  products,
  professions,
  propertyTypes,
  provinces,
  publicPositions,
  referenceTypes,
  riskLevels,
  societyTypes,
  taxRegimes,
  trustDocuments,
  trustParts,
  trusts,
  trustTypes,
} from "../../Views";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    paddingTop: theme.spacing(3),
  },
  list: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      "& > li": {
        flexBasis: "33.33%",
      },
    },
  },
}));

const SettingsAdmin = () => {
  const classes = useStyles();
  const translate = useTranslate();

  const iconColor = "rgb(144, 162, 219)";

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="overline">
            Configuración
          </Typography>
          <Typography variant="h1">Mantenimientos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="h4" component="h2">
              Persona
            </Typography>
            <Divider />
            <List
              className={classes.list}
              aria-labelledby="conguration subtitle"
            >
              <ListItemLink
                to="/person-types"
                primary={translate("resources.person-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<personTypes.icon />}
              />
              <ListItemLink
                to="/academic-levels"
                primary={translate("resources.academic-levels.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<academicLevels.icon />}
              />
              <ListItemLink
                to="/marital-statuses"
                primary={translate("resources.marital-statuses.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<maritalStatuses.icon />}
              />
              <ListItemLink
                to="/reference-types"
                primary={translate("resources.reference-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<referenceTypes.icon />}
              />
              <ListItemLink
                to="/person-relationship-types"
                primary={translate("resources.person-relationship-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<personRelationshipTypes.icon />}
              />
              <ListItemLink
                to="/economic-sectors"
                primary={translate("resources.economic-sectors.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<economicSectors.icon />}
              />
              <ListItemLink
                to="/professions"
                primary={translate("resources.professions.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<professions.icon />}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="h4" component="h2">
              Propiedades
            </Typography>
            <Divider />
            <List
              className={classes.list}
              aria-labelledby="conguration subtitle"
            >
              <ListItemLink
                to="/trusts"
                primary={translate("resources.trusts.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<trusts.icon />}
              />
              <ListItemLink
                to="/trust-parts"
                primary={translate("resources.trust-parts.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<trustParts.icon />}
              />
              <ListItemLink
                to="/risk-levels"
                primary={translate("resources.risk-levels.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<riskLevels.icon />}
              />
              <ListItemLink
                to="/property-types"
                primary={translate("resources.property-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<propertyTypes.icon />}
              />
              <ListItemLink
                to="/document-types"
                primary={translate("resources.document-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<documentTypes.icon />}
              />
              <ListItemLink
                to="/trust-documents"
                primary={translate("resources.trust-documents.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<trustDocuments.icon />}
              />
              <ListItemLink
                to="/guarantee-types"
                primary={translate("resources.guarantee-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<guaranteeTypes.icon />}
              />
              <ListItemLink
                to="/payment-methods"
                primary={translate("resources.payment-methods.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<paymentMethods.icon />}
              />
              <ListItemLink
                to="/products"
                primary={translate("resources.products.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<products.icon />}
              />
              <ListItemLink
                to="/comercial-activities"
                primary={translate("resources.comercial-activities.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<comercialActivities.icon />}
              />
              <ListItemLink
                to="/distribution-channels"
                primary={translate("resources.distribution-channels.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<distributionChannels.icon />}
              />
              <ListItemLink
                to="/trust-types"
                primary={translate("resources.trust-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<trustTypes.icon />}
              />
              <ListItemLink
                to="/contribution-amounts"
                primary={translate("resources.contribution-amounts.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<contributionAmounts.icon />}
              />
              <ListItemLink
                to="/person-company-bindings"
                primary={translate("resources.person-company-bindings.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<bindingCompanies.icon />}
              />
              <ListItemLink
                to="/person-roles"
                primary={translate("resources.person-roles.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<personRoles.icon />}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="h4" component="h2">
              Negocios
            </Typography>
            <Divider />
            <List
              className={classes.list}
              aria-labelledby="conguration subtitle"
            >
              <ListItemLink
                to="/public-positions"
                primary={translate("resources.public-positions.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<publicPositions.icon />}
              />
              <ListItemLink
                to="/society-types"
                primary={translate("resources.society-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<societyTypes.icon />}
              />
              <ListItemLink
                to="/tax-regimes"
                primary={translate("resources.tax-regimes.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<taxRegimes.icon />}
              />
              <ListItemLink
                to="/business-types"
                primary={translate("resources.business-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<businessTypes.icon />}
              />
              <ListItemLink
                to="/business-relationship-types"
                primary={translate(
                  "resources.business-relationship-types.name",
                  {
                    smart_count: 2,
                  }
                )}
                color={iconColor}
                icon={<businessRelationshipTypes.icon />}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="h4" component="h2">
              Direcciones
            </Typography>
            <Divider />
            <List
              className={classes.list}
              aria-labelledby="conguration subtitle"
            >
              <ListItemLink
                to="/countries"
                primary={translate("resources.countries.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<countries.icon />}
              />
              <ListItemLink
                to="/provinces"
                primary={translate("resources.provinces.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<provinces.icon />}
              />
              <ListItemLink
                to="/cities"
                primary={translate("resources.cities.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<cities.icon />}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SettingsAdmin;

import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  maxLength,
  email,
  FormDataConsumer,
  useTranslate,
  ListButton,
  TopToolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  minLength,
  AutocompleteInput,
} from "react-admin";

import { getRiskLevel } from "../../utils/validations";
import {
  FormTitle,
  PhoneNumberInput,
  CountrySelect,
  CustomSelectInput,
} from "../../components";
import { useSelectDataProvider } from "../../hooks";
import { FormGeneral } from "../../styles/FormGeneral";
import { LocationSelect } from "../../components/LocationSelect";
import { CountryRiskLevel, PersonTypes } from "../../utils/enums";
import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";
import {
  trustOptionRenderer,
  fullNameOptionRendererWithDocumentNumber,
  businessNameWithDocumentNumberRenderer,
} from "../../utils/optionRederers";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const LegalPersonCreate = (props) => {
  const classes = FormGeneral();

  const countries = useSelectDataProvider("countries");
  const documentTypes = useSelectDataProvider("document-types");
  const translate = useTranslate();

  return (
    <Create title=" " actions={<Actions />} {...props}>
      <TabbedForm redirect={props.basePath} variant="outlined">
        <FormTab label="General" contentClassName={classes.root}>
          <FormTitle
            title="Información personal"
            formClassName={classes.width100}
          />
          <CustomSelectInput
            formClassName={classes.width33}
            fullWidth
            source="documentTypeId"
            choices={documentTypes}
            validate={required()}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="documentNumber"
            validate={[required(), minLength(9), maxLength(20)]}
          />
          <ReferenceArrayInput
            formClassName={classes.width33}
            source="roles"
            fullWidth
            reference="person-roles"
            validate={required()}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceInput
            source="societyTypeId"
            reference="society-types"
            formClassName={classes.width33}
            fullWidth
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width33}
            fullWidth
            reference="tax-regimes"
            source="taxRegimeId"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width33}
            fullWidth
            reference="business-types"
            source="businessTypeId"
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="businessName"
            validate={required()}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="tradename"
            validate={required()}
          />
          <DateInput
            source="legalEvaluationDate"
            validate={required()}
            formClassName={classes.width33}
            fullWidth
          />
          <TextInput
            source="commercialRegister"
            validate={required()}
            formClassName={classes.width33}
            fullWidth
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="registerIssueDate"
            validate={required()}
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="registerExpirationDate"
            validate={required()}
          />
          <CountrySelect
            formClassName={classes.width25}
            fullWidth
            source="constitutionCountryId"
            choices={countries}
            validate={required()}
          />
          <DateInput
            source="constitutionDate"
            validate={required()}
            formClassName={classes.width25}
            fullWidth
          />
          <BooleanInput
            formClassName={classes.width25}
            fullWidth
            source="obligedSubject"
          />
          <BooleanInput
            formClassName={classes.width25}
            fullWidth
            source="subsidiaryCompany"
          />
          <FormTitle title="Contacto" formClassName={classes.width100} />
          <PhoneNumberInput
            formClassName={classes.width25}
            fullWidth
            source="phoneNumber"
          />
          <PhoneNumberInput
            formClassName={classes.width25}
            fullWidth
            source="otherPhoneNumber"
          />
          <TextInput
            formClassName={classes.width25}
            fullWidth
            source="email"
            type="email"
          />
          <TextInput
            formClassName={classes.width25}
            fullWidth
            source="webPage"
            type="url"
          />
          <LocationSelect
            formClassName={classes.width100}
            countries={countries}
          />
          <TextInput
            formClassName={classes.width25}
            fullWidth
            source="postalCode"
          />
        </FormTab>
        <FormTab label="Referencias" path="legalPersonReferences">
          <ArrayInput source="legalPersonReferences" className={classes.form}>
            <SimpleFormIterator>
              <FormTitle title="Referencia" formClassName={classes.width100} />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                reference="reference-types"
                source="referenceTypeId"
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="name"
                validate={required()}
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="timeAsCustomerYears"
                validate={required()}
              />
              <FormTitle title="Contacto" formClassName={classes.width100} />
              <TextInput
                formClassName={classes.width100}
                fullWidth
                source="address"
                validate={maxLength(200)}
              />
              <CountrySelect
                formClassName={classes.width33}
                fullWidth
                source="countryId"
                choices={countries}
                validate={required()}
              />
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  scopedFormData.countryId &&
                  getRiskLevel(countries, scopedFormData.countryId) !==
                    CountryRiskLevel.extreme ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ countryId: scopedFormData.countryId }}
                      reference="provinces"
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ provinceId: scopedFormData.provinceId }}
                      reference="cities"
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  )
                }
              </FormDataConsumer>
              <PhoneNumberInput
                source="phoneNumber"
                isRequired={true}
                formClassName={classes.width50}
                fullWidth
              />
              <PhoneNumberInput
                source="otherPhoneNumber"
                formClassName={classes.width50}
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab
          label="Información transaccional"
          path="legalPersonTransactionalInfos"
        >
          <ArrayInput
            source="legalPersonTransactionalInfos"
            className={classes.form}
          >
            <SimpleFormIterator>
              <FormTitle
                title="Información transaccional"
                formClassName={classes.width100}
              />
              <ReferenceAutocomplete
                formClassName={classes.width33}
                fullWidth
                validate={required()}
                source="provinceId"
                reference="provinces"
              />

              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      filter={{
                        provinceId: scopedFormData && scopedFormData.provinceId,
                      }}
                      reference="cities"
                      validate={required()}
                      source={getSource("cityId")}
                      {...rest}
                    />
                  ) : (
                    <SelectInput
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      {...rest}
                    />
                  )
                }
              </FormDataConsumer>
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="frequency"
                validate={required()}
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="income"
                validate={required()}
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="distributionChannelId"
                reference="distribution-channels"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="personCompanyBindingId"
                reference="person-company-bindings"
                validate={required()}
              >
                <SelectInput optionText="businessName" />
              </ReferenceInput>
              <ReferenceAutocomplete
                formClassName={classes.width33}
                fullWidth
                reference="trusts"
                source="trustId"
                validate={required()}
                optionText={trustOptionRenderer}
                filterToQuery={(searchText) =>
                  searchText && {
                    $or: true,
                    shortName: searchText,
                    documentNumber: searchText,
                  }
                }
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                source="productId"
                reference="products"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                source="paymentMethodId"
                reference="payment-methods"
                fullWidth
                validate={required()}
                formClassName={classes.width33}
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                source="purposeTransaction"
                fullWidth
                formClassName={classes.width33}
              />
              <TextInput
                fullWidth
                source="sourceFunds"
                formClassName={classes.width33}
              />
              <NumberInput
                formClassName={classes.width33}
                fullWidth
                source="startingAmount"
              />
              <NumberInput
                formClassName={classes.width33}
                source="estimatedMonthlyAmount"
                fullWidth
              />
              <BooleanInput
                formClassName={classes.width33}
                fullWidth
                source="transferAbroad"
              />
              <CountrySelect
                choices={countries}
                formClassName={classes.width33}
                fullWidth
                source="transferAbroadCountryId"
              />
              <BooleanInput
                formClassName={classes.width33}
                fullWidth
                source="transferFromAbroad"
              />
              <CountrySelect
                source="transferFromCountryId"
                choices={countries}
                fullWidth
                formClassName={classes.width33}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="address"
                validate={maxLength(200)}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab
          label="Subsidiarias o relacionadas"
          path="mainBusinessRelationships"
        >
          <ArrayInput
            source="mainBusinessRelationships"
            className={classes.form}
          >
            <SimpleFormIterator>
              <FormTitle
                title="Información de la subsidiarias o relacionada"
                formClassName={classes.width100}
              />
              <ReferenceInput
                formClassName={classes.width33}
                fullWidth
                reference="business-relationship-types"
                source="businessRelationshipTypeId"
              >
                <SelectInput />
              </ReferenceInput>
              <TextInput
                formClassName={classes.width33}
                source="businessName"
                fullWidth
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="managerName"
              />
              <CustomSelectInput
                formClassName={classes.width33}
                fullWidth
                source="documentTypeId"
                choices={documentTypes}
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="documentNumber"
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="managerPhoneNumber"
              />
              <FormTitle title="Contacto" formClassName={classes.width100} />
              <TextInput
                source="address"
                formClassName={classes.width50}
                fullWidth
                validate={maxLength(200)}
              />
              <PhoneNumberInput
                formClassName={classes.width50}
                fullWidth
                source="phoneNumber"
              />
              <CountrySelect
                formClassName={classes.width33}
                fullWidth
                source="countryId"
                choices={countries}
                validate={required()}
              />
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  scopedFormData.countryId &&
                  getRiskLevel(countries, scopedFormData.countryId) !==
                    CountryRiskLevel.extreme ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ countryId: scopedFormData.countryId }}
                      reference="provinces"
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ provinceId: scopedFormData.provinceId }}
                      reference="cities"
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  )
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Accionistas">
          <ArrayInput
            addLabel={false}
            className={classes.form}
            source="mainShareholders"
          >
            <SimpleFormIterator>
              <NumberInput
                source="sharePercentage"
                formClassName={classes.width50}
                fullWidth
              />
              <TextInput
                source="positionName"
                formClassName={classes.width50}
                fullWidth
              />
              <ReferenceInput
                source="personTypeId"
                formClassName={classes.width50}
                fullWidth
                reference="person-types"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <FormDataConsumer fullWidth formClassName={classes.width50}>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return !scopedFormData?.personTypeId ? (
                    <TextInput
                      {...rest}
                      source={getSource("nameOrDocument")}
                      disabled={!scopedFormData?.personTypeId}
                      label={translate(
                        "resources.trusts.fields.nameOrDocument"
                      )}
                    />
                  ) : scopedFormData?.personTypeId === PersonTypes.physical ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filterToQuery={function (searchText) {
                        return (
                          searchText && {
                            $or: true,
                            firstName: searchText,
                            secondName: searchText,
                            fatherLastName: searchText,
                            motherLastName: searchText,
                            documentNumber: searchText,
                          }
                        );
                      }}
                      fullWidth
                      validate={required()}
                      source={getSource("personId")}
                      reference="people"
                      label={translate(
                        "resources.trusts.fields.nameOrDocument"
                      )}
                      shouldRenderSuggestions={(value) => value.length > 3}
                      optionText={fullNameOptionRendererWithDocumentNumber}
                    />
                  ) : (
                    <ReferenceInput
                      {...rest}
                      filterToQuery={function (searchText) {
                        return (
                          searchText && {
                            $or: true,
                            businessName: searchText,
                            documentNumber: searchText,
                          }
                        );
                      }}
                      disabled={!scopedFormData?.personTypeId}
                      fullWidth
                      validate={required()}
                      source={getSource("referencedLegalPersonId")}
                      reference="legal-people"
                      label={translate(
                        "resources.trusts.fields.nameOrDocument"
                      )}
                    >
                      <AutocompleteInput
                        optionText={businessNameWithDocumentNumberRenderer}
                        shouldRenderSuggestions={(value) => value.length > 3}
                      />
                    </ReferenceInput>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Sucursales" path="branchOffices">
          <ArrayInput source="branchOffices" className={classes.form}>
            <SimpleFormIterator>
              <FormTitle
                title="Información de la sucursal"
                formClassName={classes.width100}
              />
              <TextInput
                source="branchOfficeName"
                validate={required()}
                formClassName={classes.width50}
                fullWidth
              />
              <TextInput
                source="branchManagerName"
                validate={required()}
                formClassName={classes.width50}
                fullWidth
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="phoneNumber"
              />
              <PhoneNumberInput
                formClassName={classes.width33}
                fullWidth
                source="otherPhone"
              />
              <TextInput
                formClassName={classes.width33}
                fullWidth
                source="email"
                validate={email()}
              />
              <TextInput
                formClassName={classes.width100}
                fullWidth
                source="address"
                validate={maxLength(250)}
              />
              <CountrySelect
                formClassName={classes.width33}
                fullWidth
                source="countryId"
                choices={countries}
                validate={required()}
              />
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  scopedFormData.countryId &&
                  getRiskLevel(countries, scopedFormData.countryId) !==
                    CountryRiskLevel.extreme ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ countryId: scopedFormData.countryId }}
                      reference="provinces"
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("provinceId")}
                      validate={required()}
                      label={translate(
                        "resources.legal-people.fields.provinceId"
                      )}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.provinceId ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filter={{ provinceId: scopedFormData.provinceId }}
                      reference="cities"
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  ) : (
                    <SelectInput
                      {...rest}
                      choices={[]}
                      disabled
                      source={getSource("cityId")}
                      validate={required()}
                      label={translate("resources.legal-people.fields.cityId")}
                    />
                  )
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default LegalPersonCreate;

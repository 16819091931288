import React from "react";
import { Sidebar } from "react-admin";
import { makeStyles, styled } from "@material-ui/core/styles";
import { compose, display } from "@material-ui/system";

const CustomSidebar = styled(Sidebar)(compose(display));

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "#fff",
    height: "100%",
    marginTop: 0,
    paddingTop: "1.5em",
    boxSizing: "border-box",
  },
});

const MySidebar = (props) => {
  const classes = useSidebarStyles();
  return (
    <CustomSidebar
      displayPrint="none"
      classes={{ root: classes.drawerPaper }}
      {...props}
    />
  );
};

export default MySidebar;

import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import TrustTypeCreate from "./TrustTypeCreate";
import TrustTypeEdit from "./TrustTypeEdit";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="ra.action.search" source="name" alwaysOn />
  </Filter>
);

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const TrustTypeList = (props) => {
  const classes = FormGeneral();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/trust-types");
  }, [history]);

  return (
    <Fragment>
      <List filters={<Filters />} actions={<Actions />} {...props}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <EditButton />
        </Datagrid>
      </List>
      <Route path="/trust-types/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <TrustTypeCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path="/trust-types/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <TrustTypeEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default TrustTypeList;

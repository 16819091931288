import React from "react";
import {
  Edit,
  SimpleForm,
  TopToolbar,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const CityEdit = (props) => (
  <Edit title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <ReferenceAutocomplete
        source="countryId"
        reference="countries"
        validate={required()}
        fullWidth
      />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.countryId && (
            <ReferenceInput
              {...rest}
              filter={{ countryId: formData.countryId }}
              reference="provinces"
              source="provinceId"
              validate={required()}
            >
              <SelectInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <TextInput source="name" validate={required()} fullWidth />
    </SimpleForm>
  </Edit>
);

export default CityEdit;

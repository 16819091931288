import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    padding: "0.3rem",
    fontSize: "12px !important",
  },
}));

const ShowParagraph = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography gutterBottom className={classes.paragraph} variant="body2">
      {children}
    </Typography>
  );
};

export default ShowParagraph;

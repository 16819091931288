import React from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";
import { autocompleteOptions } from "../utils/autocompleteOptions";

export default ({
  inputProps,
  optionText = "name",
  shouldRenderSuggestions,
  filterToQuery,
  ...props
}) => {
  return (
    <ReferenceInput
      filterToQuery={
        filterToQuery ||
        function () {
          return null;
        }
      }
      {...props}
    >
      <AutocompleteInput
        optionText={optionText}
        options={autocompleteOptions}
        suggestionLimit={50}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </ReferenceInput>
  );
};

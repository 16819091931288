import React from "react";
import {
  Create,
  ListButton,
  ReferenceInput,
  required,
  SelectInput,
  FormTab,
  TextInput,
  TabbedForm,
  TopToolbar,
  DateInput,
  NumberInput,
  maxValue,
  maxLength,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";

import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";
import { PersonTypes } from "../../utils/enums";
import { FormGeneral } from "../../styles/FormGeneral";
import {
  fullNameOptionRendererWithDocumentNumber,
  businessNameWithDocumentNumberRenderer,
} from "../../utils/optionRederers";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const TrustCreate = (props) => {
  const classes = FormGeneral();
  const translate = useTranslate();

  return (
    <Create title="" {...props} actions={<Actions {...props} />}>
      <TabbedForm variant="outlined" redirect={props.basePath}>
        <FormTab label="Fideicomisos" contentClassName={classes.root}>
          <ReferenceInput
            formClassName={classes.width33}
            fullWidth
            reference="document-types"
            source="documentTypeId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="documentNumber"
            validate={required()}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="name"
            validate={[required(), maxLength(100)]}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="shortName"
            validate={[required()]}
          />
          <ReferenceInput
            formClassName={classes.width33}
            fullWidth
            reference="trust-types"
            source="trustTypeId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="projectName"
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="constitutionDate"
            validate={[required()]}
          />
          <NumberInput
            formClassName={classes.width33}
            fullWidth
            source="termOfTheContract"
            validate={[required(), maxValue(31)]}
          />
          <SelectInput
            formClassName={classes.width33}
            fullWidth
            source="timeFrame"
            choices={[
              { id: "days", name: "Dias" },
              { id: "months", name: "Meses" },
              { id: "years", name: "Años" },
            ]}
            validate={required()}
          />
          <DateInput
            formClassName={classes.width33}
            fullWidth
            source="registrationTradeBedDate"
            validate={[required()]}
          />
          <TextInput
            formClassName={classes.width33}
            fullWidth
            source="registrationTradeBedDateNumber"
            validate={[required()]}
          />
          <ReferenceInput
            formClassName={classes.width33}
            fullWidth
            reference="products"
            source="productId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width25}
            fullWidth
            reference="contribution-amounts"
            source="contributionAmountId"
            validate={[required()]}
          >
            <SelectInput optionText="amount" />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width75}
            fullWidth
            multiline
            rows="4"
            source="object"
            validate={[required()]}
          />
        </FormTab>
        <FormTab label="Partes de fideicomisos" path="trustTypes">
          <ArrayInput source="trustPartsDetails" className={classes.form}>
            <SimpleFormIterator>
              <ReferenceInput
                source="trustPartId"
                formClassName={classes.width33}
                fullWidth
                reference="trust-parts"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <ReferenceInput
                source="personTypeId"
                formClassName={classes.width33}
                fullWidth
                reference="person-types"
                validate={required()}
              >
                <SelectInput />
              </ReferenceInput>
              <FormDataConsumer fullWidth formClassName={classes.width33}>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return !scopedFormData?.personTypeId ? (
                    <TextInput
                      {...rest}
                      source={getSource("nameOrDocument")}
                      disabled={!scopedFormData?.personTypeId}
                      label={translate(
                        "resources.trusts.fields.nameOrDocument"
                      )}
                    />
                  ) : scopedFormData?.personTypeId === PersonTypes.physical ? (
                    <ReferenceAutocomplete
                      {...rest}
                      filterToQuery={function (searchText) {
                        return (
                          searchText && {
                            $or: true,
                            firstName: searchText,
                            secondName: searchText,
                            fatherLastName: searchText,
                            motherLastName: searchText,
                            documentNumber: searchText,
                          }
                        );
                      }}
                      shouldRenderSuggestions={(value) => value.length > 3}
                      fullWidth
                      validate={required()}
                      source={getSource("personId")}
                      reference="people"
                      optionText={fullNameOptionRendererWithDocumentNumber}
                      label={translate(
                        "resources.trusts.fields.nameOrDocument"
                      )}
                    />
                  ) : (
                    scopedFormData?.personTypeId === PersonTypes.legal && (
                      <ReferenceInput
                        {...rest}
                        filterToQuery={function (searchText) {
                          return (
                            searchText && {
                              $or: true,
                              businessName: searchText,
                              documentNumber: searchText,
                            }
                          );
                        }}
                        disabled={!scopedFormData?.personTypeId}
                        fullWidth
                        validate={required()}
                        source={getSource("legalPersonId")}
                        reference="legal-people"
                        label={translate(
                          "resources.trusts.fields.nameOrDocument"
                        )}
                      >
                        <AutocompleteInput
                          optionText={businessNameWithDocumentNumberRenderer}
                          shouldRenderSuggestions={(value) => value.length > 3}
                        />
                      </ReferenceInput>
                    )
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default TrustCreate;

import React from "react";
import { Labeled, DateField } from "react-admin";

const DatagridDateField = ({ id, data, source, resource, ...props }) => {
  return (
    <Labeled label={`resources.${resource}.fields.${source}`}>
      <DateField emptyText="–" record={data[id]} source={source} {...props} />
    </Labeled>
  );
};

export default DatagridDateField;

import React from "react";
import { AppBar } from "react-admin";
import clsx from "clsx";
import { makeStyles, styled } from "@material-ui/styles";
import { compose, display } from "@material-ui/system";

const CustomAppBar = styled(AppBar)(compose(display));

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const MyAppBar = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <CustomAppBar
      {...rest}
      displayPrint="none"
      className={clsx(classes.root, className)}
      color="primary"
    ></CustomAppBar>
  );
};

export default MyAppBar;

import CardTravelIcon from "@material-ui/icons/CardTravel";
import TrustList from "./TrustList";
import TrustCreate from "./TrustCreate";
import TrustEdit from "./TrustEdit";
import TrustShow from "./TrustShow";

export default {
  list: TrustList,
  create: TrustCreate,
  edit: TrustEdit,
  show: TrustShow,
  icon: CardTravelIcon,
};

import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
  ReferenceField,
  ShowButton,
} from "react-admin";

import { Roles } from "../../utils/enums";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="Buscar por nombre" source="name" resettable alwaysOn />
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    {permissions === Roles.admin && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

const ProfessionList = ({ permissions, ...props }) => {
  return (
    <Fragment>
      <List
        filters={<Filters />}
        bulkActionButtons={permissions === Roles.admin ? undefined : false}
        sort={{ field: "id", order: "DESC" }}
        actions={<Actions permissions={permissions} />}
        perPage={25}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <ReferenceField
            source="trustTypeId"
            reference="trust-types"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />
          <ReferenceField
            source="contributionAmountId"
            reference="contribution-amounts"
            link={false}
          >
            <TextField source="amount" />
          </ReferenceField>
          {permissions === Roles.admin && <EditButton />}
          <ShowButton />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default ProfessionList;

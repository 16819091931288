import React from "react";
import {
  ArrayField,
  Button,
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  ShowController,
  ShowView,
  SingleFieldList,
  TextField,
} from "react-admin";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import PrintIcon from "@material-ui/icons/Print";

import ClientJobsIterator from "./ShowFormComponents/ClientJobsIterator";
import PersonPepIterator from "./ShowFormComponents/PersonPepIterator";
import PersonPropertiesIterator from "./ShowFormComponents/PersonPropertiesIterator";
import PersonReferencesIterator from "./ShowFormComponents/PersonReferencesIterator";
import TransactionalInfoIterator from "./ShowFormComponents/TransactionalInfoIterator";
import Logo from "./../../assets/images/logo-dinamis.png";
import { PrintShow } from "../../styles/PrintShow";
import MultipleNationalitiesIterator from "./ShowFormComponents/MultipleNationalitiesIterator";
import ShowTitle from "../../components/ShowTitle";
import ShowParagraph from "../../components/ShowParagraph";
import PersonFatcaIterator from "./ShowFormComponents/PersonFatcaIterator";

const PersonShow = (props) => {
  const classes = PrintShow();

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          {...props}
          {...controllerProps}
          translate={null}
          title=" "
          component="div"
          actions={<></>}
        >
          <GridShowLayout>
            <RaGrid
              container
              justify="flex-end"
              classes={{ root: classes.printButton }}
            >
              <RaGrid item>
                <Button
                  variant="contained"
                  onClick={() => window.print()}
                  label="Imprimir"
                >
                  <PrintIcon />
                </Button>
              </RaGrid>
            </RaGrid>
            <RaGrid container classes={{ root: classes.onlyPrint }}>
              <RaGrid item xs={6}>
                <img height="80px" src={Logo} alt="" />
              </RaGrid>
              <RaGrid item xs={6}>
                <h3 style={{ textAlign: "right" }}>
                  Vinculación de persona física
                </h3>
              </RaGrid>
            </RaGrid>
            <RaGrid container classes={{ root: classes.root }}>
              <RaGrid item xs={12}>
                <ShowTitle title="Info" />
              </RaGrid>
              <RaGrid item xs={12}>
                <ReferenceManyField
                  label="Roles"
                  reference="person-roles"
                  target="id"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceManyField>
              </RaGrid>
              <RaGrid item xs={6}>
                <FunctionField
                  label="Nombres"
                  render={(record) =>
                    `${record.firstName} ${record.secondName}`
                  }
                />
              </RaGrid>
              <RaGrid item xs={6}>
                <FunctionField
                  label="Apellidos"
                  render={(record) =>
                    `${record.fatherLastName} ${record.motherLastName}`
                  }
                />
              </RaGrid>

              <RaGrid item xs={4}>
                <TextField emptyText="–" source="sex" />
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="academicLevelId"
                  reference="academic-levels"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="maritalStatusId"
                  reference="marital-statuses"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={4}>
                <TextField emptyText="–" source="phoneNumber" />
              </RaGrid>
              <RaGrid item xs={4}>
                <TextField emptyText="–" source="cellPhone" />
              </RaGrid>
              <RaGrid item xs={4}>
                <TextField emptyText="–" source="otherPhone" />
              </RaGrid>
              <RaGrid item xs={12}>
                <TextField emptyText="–" source="email" />
              </RaGrid>
              <RaGrid item xs={9}>
                <TextField emptyText="–" source="address" />
              </RaGrid>
              <RaGrid item xs={3}>
                <TextField emptyText="–" source="postalCode" />
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="countryId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="provinceId"
                  reference="provinces"
                  link={false}
                  emptyText="–"
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField source="cityId" reference="cities" link={false}>
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="residenceCountryId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="documentTypeId"
                  reference="document-types"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <TextField emptyText="–" source="documentNumber" />
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="documentIssuingCountry"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="documentExpirationDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <TextField emptyText="–" source="birthday" />
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="birthCountryId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="nationalityId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="nationality" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <FunctionField
                  render={(record) => (record.foreign ? "Sí" : "No")}
                  source="foreign"
                />
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.personMultipleNationalities.length >
                  0 ? (
                  <ArrayField
                    addLabel={false}
                    source="personMultipleNationalities"
                  >
                    <MultipleNationalitiesIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                <ArrayField addLabel={false} source="personFatca">
                  <PersonFatcaIterator />
                </ArrayField>
              </RaGrid>
              <RaGrid container item xs={12}>
                {controllerProps.record &&
                controllerProps.record.clientJobs.length > 0 ? (
                  <ArrayField addLabel={false} source="clientJobs">
                    <ClientJobsIterator />
                  </ArrayField>
                ) : (
                  <> </>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.personPep.length > 0 ? (
                  <ArrayField addLabel={false} source="personPep">
                    <PersonPepIterator />
                  </ArrayField>
                ) : (
                  <> </>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.personProperties.length > 0 ? (
                  <ArrayField addLabel={false} source="personProperties">
                    <PersonPropertiesIterator />
                  </ArrayField>
                ) : (
                  <> </>
                )}
              </RaGrid>

              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.personProperties.length > 0 ? (
                  <ArrayField addLabel={false} source="personReferences">
                    <PersonReferencesIterator />
                  </ArrayField>
                ) : (
                  <> </>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.transactionalInfo.length > 0 ? (
                  <ArrayField addLabel={false} source="transactionalInfo">
                    <TransactionalInfoIterator />
                  </ArrayField>
                ) : (
                  <> </>
                )}
              </RaGrid>
              <RaGrid
                item
                container
                xs={12}
                classes={{ root: classes.onlyPrint }}
              >
                <RaGrid item xs={12}>
                  <ShowTitle title="Declaración jurada y firma del cliente" />
                </RaGrid>
                <RaGrid item xs={12}>
                  <ShowParagraph>
                    El cliente y su cónyuge (si aplica), por medio del presente
                    documento declaran bajo juramento que los datos consignados
                    en esta solicitud son verídicos, correctos y que se obligan
                    a notificar por escrito sobre cualquier modificación en los
                    mismos. Por medio del presente documento AUTORIZAMOS a la
                    sociedad Dinamis Trustee DT S.A., titular del Registro
                    Mercantil número 104385SD y Registro Nacional de
                    Contribuyentes No. 131132936, y a sus representantes,
                    empleados y personal en general a consultar mi historial
                    crediticio para obtener información sobre mi comportamiento
                    crediticio a fin de poder realizar negocios o vincularme a
                    un nuevo fideicomiso o uno ya constituido y administrado por
                    dicha sociedad.
                    <br />
                    <br />
                    El cliente y su cónyuge (si aplica), por medio del presente
                    documento declaran bajo juramento que los datos consignados
                    en esta solicitud son verídicos, correctos y que se obligan
                    a notificar por escrito sobre cualquier modificación en los
                    mismos. Por medio del presente documento AUTORIZAMOS a la
                    sociedad Dinamis Trustee DT S.A., titular del Registro
                    Mercantil número 104385SD y Registro Nacional de
                    Contribuyentes No. 131132936, y a sus representantes,
                    empleados y personal en general a consultar mi historial
                    crediticio para obtener información sobre mi comportamiento
                    crediticio a fin de poder realizar negocios o vincularme a
                    un nuevo fideicomiso o uno ya constituido y administrado por
                    dicha sociedad.
                  </ShowParagraph>
                </RaGrid>
                <RaGrid container item xs={6}>
                  <RaGrid item xs={12}>
                    <TextField label="Firma por Dinamis Trustee DT S.A." />
                  </RaGrid>
                  <RaGrid item xs={12}>
                    <TextField label="Fecha" />
                  </RaGrid>
                </RaGrid>
                <RaGrid container item xs={6}>
                  <RaGrid item xs={12} className={classes.signature}>
                    <TextField label="Firma por el cliente" />
                  </RaGrid>
                  <RaGrid item xs={12} className={classes.signature}>
                    <TextField label="Fecha" />
                  </RaGrid>
                </RaGrid>
              </RaGrid>
            </RaGrid>
          </GridShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default PersonShow;

import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const BindingCompanyEdit = (props) => (
  <Edit title=" " actions={<Actions {...props} />} {...props}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput disabled source="id" fullWidth />
      <TextInput source="documentNumber" fullWidth validate={required()} />
      <TextInput source="businessName" fullWidth validate={required()} />
      <ReferenceInput
        source="trustId"
        reference="trusts"
        filterToQuery={(searchText) => ({ name: searchText || " " })}
        fullWidth
      >
        <AutocompleteInput
          optionText="name"
          shouldRenderSuggestions={(value) => value.length > 2}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default BindingCompanyEdit;

import React from "react";
import {
  ArrayField,
  Button,
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  ShowController,
  ShowView,
  SingleFieldList,
  TextField,
} from "react-admin";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import PrintIcon from "@material-ui/icons/Print";

import Logo from "./../../assets/images/logo-dinamis.png";
import { PrintShow } from "../../styles/PrintShow";
import ShowTitle from "../../components/ShowTitle";
import LegalPersonRefencesIterator from "./ShowFormComponents/LegalPersonReferencesIterator";
import LegalPersonTransactionalInfosIterator from "./ShowFormComponents/LegalPersonTransactionalInfosIterator";
import MainBusinessRelationshipsIterator from "./ShowFormComponents/MainBusinessRelationshipsIterator";
import MainShareholdersIterator from "./ShowFormComponents/MainShareholdersIterator";
import BranchOfficesIterator from "./ShowFormComponents/BranchOfficesIterator";
import ShowParagraph from "../../components/ShowParagraph";

const LegalPersonShow = (props) => {
  const classes = PrintShow();

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          actions={<></>}
          {...props}
          {...controllerProps}
          translate={null}
          title=" "
          component="div"
        >
          <GridShowLayout>
            <RaGrid
              container
              justify="flex-end"
              classes={{ root: classes.printButton }}
            >
              <RaGrid item>
                <Button
                  variant="contained"
                  onClick={() => window.print()}
                  label="Imprimir"
                >
                  <PrintIcon />
                </Button>
              </RaGrid>
            </RaGrid>
            <RaGrid container classes={{ root: classes.onlyPrint }}>
              <RaGrid item xs={6}>
                <img height="80px" src={Logo} alt="" />
              </RaGrid>
              <RaGrid item xs={6}>
                <ShowTitle variant="h4" title="Información personal" />
              </RaGrid>
            </RaGrid>
            <RaGrid container classes={{ root: classes.root }}>
              <RaGrid item xs={12}>
                <ShowTitle title="Información personal" />
              </RaGrid>
              <RaGrid item xs={12}>
                <ReferenceManyField
                  label="Roles"
                  reference="person-roles"
                  target="id"
                >
                  <SingleFieldList>
                    <ChipField emptyText="-" source="name" />
                  </SingleFieldList>
                </ReferenceManyField>
              </RaGrid>
              <RaGrid item xs={4}>
                <TextField emptyText="–" source="businessName" />
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="documentTypeId"
                  reference="document-types"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>

              <RaGrid item xs={4}>
                <TextField emptyText="–" source="documentNumber" />
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="societyTypeId"
                  reference="society-types"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="taxRegimeId"
                  reference="tax-regimes"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={4}>
                <ReferenceField
                  source="businessTypeId"
                  reference="business-types"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>

              <RaGrid item xs={9}>
                <TextField emptyText="–" source="tradename" />
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="legalEvaluationDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <TextField emptyText="–" source="commercialRegister" />
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="registerIssueDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="registerExpirationDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="operationsStartDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="constitutionCountryId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <DateField emptyText="–" source="constitutionDate" />
              </RaGrid>
              <RaGrid item xs={3}>
                <FunctionField
                  render={(record) => (record.obligedSubject ? "Sí" : "No")}
                  source="obligedSubject"
                />
              </RaGrid>
              <RaGrid item xs={3}>
                <FunctionField
                  render={(record) => (record.obligedSubject ? "Sí" : "No")}
                  source="subsidiaryCompany"
                />
              </RaGrid>
              <RaGrid item xs={6}>
                <TextField emptyText="–" source="phoneNumber" />
              </RaGrid>
              <RaGrid item xs={6}>
                <TextField emptyText="–" source="otherPhoneNumber" />
              </RaGrid>
              <RaGrid item xs={6}>
                <TextField emptyText="–" source="email" />
              </RaGrid>
              <RaGrid item xs={6}>
                <TextField emptyText="–" source="webPage" />
              </RaGrid>

              <RaGrid item xs={3}>
                <ReferenceField
                  source="countryId"
                  reference="countries"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField
                  source="provinceId"
                  reference="provinces"
                  link={false}
                >
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <ReferenceField source="cityId" reference="cities" link={false}>
                  <TextField emptyText="–" source="name" />
                </ReferenceField>
              </RaGrid>
              <RaGrid item xs={3}>
                <TextField emptyText="–" source="postalCode" />
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.legalPersonReferences.length > 0 ? (
                  <ArrayField addLabel={false} source="legalPersonReferences">
                    <LegalPersonRefencesIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.legalPersonTransactionalInfos.length >
                  0 ? (
                  <ArrayField
                    addLabel={false}
                    source="legalPersonTransactionalInfos"
                  >
                    <LegalPersonTransactionalInfosIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.mainBusinessRelationships.length > 0 ? (
                  <ArrayField
                    addLabel={false}
                    source="mainBusinessRelationships"
                  >
                    <MainBusinessRelationshipsIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.mainShareholders.length > 0 ? (
                  <ArrayField addLabel={false} source="mainShareholders">
                    <MainShareholdersIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid item xs={12}>
                {controllerProps.record &&
                controllerProps.record.branchOffices.length > 0 ? (
                  <ArrayField addLabel={false} source="branchOffices">
                    <BranchOfficesIterator />
                  </ArrayField>
                ) : (
                  <></>
                )}
              </RaGrid>
              <RaGrid
                item
                container
                xs={12}
                classes={{ root: classes.onlyPrint }}
              >
                <RaGrid item xs={12}>
                  <ShowTitle title="Declaración jurada y firma del cliente" />
                </RaGrid>
                <RaGrid item xs={12}>
                  <ShowParagraph>
                    El cliente y su cónyuge (si aplica), por medio del presente
                    documento declaran bajo juramento que los datos consignados
                    en esta solicitud son verídicos, correctos y que se obligan
                    a notificar por escrito sobre cualquier modificación en los
                    mismos. Por medio del presente documento AUTORIZAMOS a la
                    sociedad Dinamis Trustee DT S.A., titular del Registro
                    Mercantil número 104385SD y Registro Nacional de
                    Contribuyentes No. 131132936, y a sus representantes,
                    empleados y personal en general a consultar mi historial
                    crediticio para obtener información sobre mi comportamiento
                    crediticio a fin de poder realizar negocios o vincularme a
                    un nuevo fideicomiso o uno ya constituido y administrado por
                    dicha sociedad.
                    <br />
                    <br />
                    El cliente y su cónyuge (si aplica), por medio del presente
                    documento declaran bajo juramento que los datos consignados
                    en esta solicitud son verídicos, correctos y que se obligan
                    a notificar por escrito sobre cualquier modificación en los
                    mismos. Por medio del presente documento AUTORIZAMOS a la
                    sociedad Dinamis Trustee DT S.A., titular del Registro
                    Mercantil número 104385SD y Registro Nacional de
                    Contribuyentes No. 131132936, y a sus representantes,
                    empleados y personal en general a consultar mi historial
                    crediticio para obtener información sobre mi comportamiento
                    crediticio a fin de poder realizar negocios o vincularme a
                    un nuevo fideicomiso o uno ya constituido y administrado por
                    dicha sociedad.
                  </ShowParagraph>
                </RaGrid>
                <RaGrid container item xs={6}>
                  <RaGrid item xs={12}>
                    <TextField label="Firma por Dinamis Trustee DT S.A." />
                  </RaGrid>
                  <RaGrid item xs={12}>
                    <TextField label="Fecha" />
                  </RaGrid>
                </RaGrid>
                <RaGrid container item xs={6}>
                  <RaGrid item xs={12} className={classes.signature}>
                    <TextField label="Firma por el cliente" />
                  </RaGrid>
                  <RaGrid item xs={12} className={classes.signature}>
                    <TextField label="Fecha" />
                  </RaGrid>
                </RaGrid>
              </RaGrid>
            </RaGrid>
          </GridShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default LegalPersonShow;

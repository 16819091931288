import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EmailField,
  EditButton,
  ShowButton,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      label="Buscar por nombre"
      source="tradename"
      resettable
      alwaysOn
    />
    <TextInput source="businessName" resettable alwaysOn />
    <TextInput source="email" />
    <TextInput source="phoneNumber" />
  </Filter>
);

const LegalPersonList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }} filters={<Filters />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="tradename" />
      <TextField source="businessName" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default LegalPersonList;

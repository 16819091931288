import React from "react";
import { FormDataConsumer, SelectInput, required } from "react-admin";
import { useForm } from "react-final-form";

import { FormGeneral } from "../styles/FormGeneral";
import CountrySelect from "./CountrySelect";
import { CountryRiskLevel } from "../utils/enums";
import { getRiskLevel } from "../utils/validations";
import ReferenceAutocomplete from "./ReferenceAutocomplete";
import AutoCompleteCities from "./AutoCompleteCities";

export const Selects = ({ formData, countries, source, ...props }) => {
  const classes = FormGeneral();
  const form = useForm();

  const hasCountryId =
    source && formData.hasOwnProperty(source)
      ? formData[source].countryId
      : formData.countryId;
  const hasProvinceId =
    source && formData.hasOwnProperty(source)
      ? formData[source].provinceId
      : formData.provinceId;

  const formChangeProvince = () => {
    form.change(source ? `${source}.provinceId` : "provinceId", null);
  };

  return (
    <>
      <div className={classes.width33}>
        <CountrySelect
          choices={countries}
          className={classes.width100}
          source={source ? `${source}.countryId` : "countryId"}
          validate={required()}
          countryChange={formChangeProvince}
          {...props}
        />
      </div>
      {hasCountryId &&
      getRiskLevel(countries, hasCountryId) !== CountryRiskLevel.extreme ? (
        <div className={classes.width33}>
          <ReferenceAutocomplete
            className={classes.width100}
            filter={{
              countryId: source
                ? formData[source].countryId
                : formData.countryId,
            }}
            reference="provinces"
            perPage={50}
            source={source ? `${source}.provinceId` : "provinceId"}
            validate={required()}
            onChange={() =>
              form.change(source ? `${source}.cityId` : "cityId", null)
            }
            {...props}
          />
        </div>
      ) : (
        <SelectInput
          className={classes.width33}
          choices={[]}
          disabled
          source={source ? `${source}.provinceId` : "provinceId"}
          validate={required()}
          {...props}
        />
      )}
      {hasProvinceId ? (
        <div className={classes.width33}>
          <AutoCompleteCities
            className={classes.width100}
            filter={{
              provinceId: source
                ? formData[source].provinceId
                : formData.provinceId,
            }}
            reference="cities"
            perPage={50}
            source={source ? `${source}.cityId` : "cityId"}
            validate={required()}
            {...props}
          />
        </div>
      ) : (
        <SelectInput
          className={classes.width33}
          choices={[]}
          disabled
          source={source ? `${source}.cityId` : "cityId"}
          validate={required()}
          {...props}
        />
      )}
    </>
  );
};

export const LocationSelect = ({
  id,
  className,
  formClassName,
  countries,
  source,
  ...props
}) => {
  const classes = FormGeneral();

  return (
    <div className={classes.root}>
      <FormDataConsumer>
        {(formDataProps) => (
          <Selects
            {...formDataProps}
            countries={countries}
            source={source}
            {...props}
          />
        )}
      </FormDataConsumer>
    </div>
  );
};

import React from "react";
import { TextField, Labeled } from "react-admin";

const DatagridTextField = ({ id, data, source, resource, ...props }) => {
  return (
    <Labeled label={`resources.${resource}.fields.${source}`}>
      <TextField emptyText="–" record={data[id]} source={source} {...props} />
    </Labeled>
  );
};

export default DatagridTextField;

import React from "react";
import {
  Create,
  SimpleForm,
  TopToolbar,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReferenceAutocomplete from "../../components/ReferenceAutocomplete";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const CityCreate = (props) => (
  <Create title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm redirect={props.basePath} variant="outlined">
      <ReferenceAutocomplete
        source="countryId"
        reference="countries"
        fullWidth
      />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.countryId && (
            <ReferenceInput
              {...rest}
              filter={{ countryId: formData.countryId }}
              reference="provinces"
              source="provinceId"
              validate={required()}
            >
              <SelectInput />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <TextInput source="name" validate={required()} fullWidth />
    </SimpleForm>
  </Create>
);

export default CityCreate;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: "0.3rem",
    fontWeight: "800",
    paddingTop: "0.75rem",
    borderBottom: `black 4px solid`,
    borderTop: `#888 1px solid`,
    marginBottom: 0,
  },
}));

const ShowTitle = ({ title, variant = "h4", basePath, ...props }) => {
  const classes = useStyles();

  return (
    <Typography
      gutterBottom
      className={classes.title}
      variant={variant}
      {...props}
    >
      {title}
    </Typography>
  );
};

ShowTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ShowTitle;

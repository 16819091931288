export const PersonTypes = {
  physical: 1,
  legal: 2,
};

export const Roles = {
  admin: "admin",
  user: "normal",
};

export const CountryRiskLevel = {
  extreme: 4,
  high: 3,
  low: 1,
  medium: 2,
};

export const PersonRoles = {
  creditor: 9,
};

import React from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";
import { autocompleteOptions } from "../utils/autocompleteOptions";

export default (props) => {
  const optionRenderer = (choice) =>
    choice.provinceId === props.filter.provinceId ? choice.name : null;
  return (
    <ReferenceInput filterToQuery={() => null} {...props}>
      <AutocompleteInput
        optionText={optionRenderer}
        suggestionLimit={50}
        options={autocompleteOptions}
      />
    </ReferenceInput>
  );
};

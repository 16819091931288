import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import {
  DatagridTextField,
  DatagridReferenceTextField,
  DatagridFunctionField,
} from "../../../components/";
import ShowTitle from "../../../components/ShowTitle";

const PersonPepIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Declaración jurada de personas expuestas políticamente (PEP)" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              source="publicOfficer"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="publicPositionId"
              reference="public-positions"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="publicOffice"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridFunctionField
              resource="people"
              source="familyPublicOfficer"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="relationshipId"
              reference="person-relationship-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="familyPublicPositionId"
              reference="public-positions"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

PersonPepIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default PersonPepIterator;

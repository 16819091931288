import React from "react";
import {
  Edit,
  SimpleForm,
  TopToolbar,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const CountryEdit = (props) => (
  <Edit title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="nationality" fullWidth />
      <NumberInput source="isoId" disabled fullWidth />
      <ReferenceInput
        fullWidth
        reference="risk-levels"
        source="riskId"
        validate={required()}
      >
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default CountryEdit;

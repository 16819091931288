import React, { useState } from "react";
import { AutocompleteInput } from "react-admin";
import { autocompleteOptions } from "../utils/autocompleteOptions";

import { CountryRiskLevel } from "../utils/enums";
import { getRiskLevel } from "../utils/validations";
import GafiText from "./GafiText";

const CountrySelect = (props) => {
  const [useRiskId, setRiskId] = useState(0);

  const doubleExecutable = (value) => {
    setRiskId(getRiskLevel(props.choices, value));
    if (props.countryChange && props.countryChange());
  };

  const optionRenderer = (choice) =>
    // eslint-disable-next-line no-useless-concat
    countryToFlag(choice.code) +
    "\x20" +
    choice.name +
    "\x20" +
    "(" +
    choice.code +
    ")";

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  return (
    <>
      <AutocompleteInput
        {...props}
        onChange={doubleExecutable}
        suggestionLimit={50}
        optionText={optionRenderer}
        options={autocompleteOptions}
      />
      {useRiskId === CountryRiskLevel.extreme && <GafiText />}
    </>
  );
};
export default CountrySelect;

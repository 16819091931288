import React from "react";
import { SelectInput, LinearProgress, Labeled } from "react-admin";

const CustomSelectInput = (props) => {
  return !props.choices ? (
    <Labeled label={`resources.${props.resource}.fields.${props.id}`}>
      <LinearProgress />
    </Labeled>
  ) : (
    <SelectInput {...props} />
  );
};

export default CustomSelectInput;

import React from "react";
import { Route } from "react-router-dom";

import { settingsAdmin } from "./Views/SettingsAdmin";
// import { ForbiddenPage } from "./403";

export default [
  <Route exact path="/settings/maintenances" component={settingsAdmin} />,
  // <Route exact path="/403" component={ForbiddenPage} />,
];

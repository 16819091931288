import React from "react";
import { FunctionField, Labeled } from "react-admin";

const DatagridFunctionField = ({ id, data, source, resource }) => {
  return (
    <Labeled label={`resources.${resource}.fields.${source}`}>
      <FunctionField
        emptyText="–"
        render={(record) => (record[source] ? "Sí" : "No")}
        record={data[id]}
        source={source}
      />
    </Labeled>
  );
};

export default DatagridFunctionField;

import React from "react";
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
} from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const BindingCompanyCreate = (props) => (
  <Create title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm redirect={props.basePath} variant="outlined">
      <TextInput source="documentNumber" fullWidth validate={required()} />
      <TextInput
        source="businessName"
        fullWidth
        validate={[required(), maxLength(50)]}
      />
      <ReferenceInput
        source="trustId"
        reference="trusts"
        filterToQuery={(searchText) => ({ name: searchText || " " })}
        fullWidth
      >
        <AutocompleteInput
          optionText="name"
          shouldRenderSuggestions={(value) => value.length > 2}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default BindingCompanyCreate;

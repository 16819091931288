import React from "react";
import { Edit, SimpleForm, TopToolbar, TextInput, required } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const PersonRoleEdit = (props) => (
  <Edit title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
    </SimpleForm>
  </Edit>
);

export default PersonRoleEdit;

import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import TaxRegimeCreate from "./TaxRegimeCreate";
import TaxRegimeEdit from "./TaxRegimeEdit";
import { Roles } from "../../utils/enums";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="ra.action.search" source="name" alwaysOn />
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    {permissions === Roles.admin && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

const TaxRegimeList = ({ permissions, ...props }) => {
  const classes = FormGeneral();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/tax-regimes");
  }, [history]);

  return (
    <Fragment>
      <List
        filters={<Filters />}
        actions={<Actions permissions={permissions} />}
        bulkActionButtons={permissions === Roles.admin ? undefined : false}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          {permissions === Roles.admin && <EditButton />}
        </Datagrid>
      </List>
      {permissions === Roles.admin && (
        <Route path="/tax-regimes/create">
          {({ match }) => (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <TaxRegimeCreate
                className={classes.drawerContent}
                onCancel={handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
      )}
      {permissions === Roles.admin && (
        <Route path="/tax-regimes/:id">
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.params &&
              match.params.id !== "create"
            );

            return (
              <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                {isMatch ? (
                  <TaxRegimeEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      )}
    </Fragment>
  );
};

export default TaxRegimeList;

import React from "react";
import {
  Edit,
  SimpleForm,
  TopToolbar,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  maxLength,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const TrustDocumentEdit = (props) => (
  <Edit title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <ReferenceInput
        source="documentTypeId"
        reference="trust-document-types"
        fullWidth
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
      <TextInput
        source="name"
        validate={[required(), maxLength(100)]}
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

export default TrustDocumentEdit;

import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  TopToolbar,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const ProfessionEdit = (props) => (
  <Edit title=" " actions={<Actions {...props} />} {...props}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" fullWidth validate={required()} />
      <ReferenceInput source="riskLevelId" reference="risk-levels" fullWidth>
        <SelectInput validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default ProfessionEdit;

import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Notification } from "react-admin";

import { theme } from "../theme";
import MyAppBar from "./MyAppBar";
import MySidebar from "./MySidebar";
import MyMenu from "./MyMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    minWidth: "fit-content",
    width: "100%",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    "@media screen": {
      [theme.breakpoints.up("xs")]: {
        marginTop: theme.spacing(8),
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7),
      "@media print": {
        marginTop: theme.spacing(0),
      },
    },
  },
  sidebar: {
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
    minHeight: "calc(100vh - 64px)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "16px",
    },
  },

  content: {
    display: "flex",
    flexBasis: 0,
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box;",
      height: "calc(100vh - 64px)",
      overflow: "auto",
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

const MyLayout = ({ children, dashboard, logout, title }) => {
  const classes = useStyles();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <MyAppBar title={title} open={open} logout={logout} />
          <main className={classes.contentWithSidebar}>
            <MySidebar className={classes.sidebar}>
              <MyMenu hasDashboard={!!dashboard} dense={true} />
            </MySidebar>
            <div className={classes.content}>{children}</div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default MyLayout;

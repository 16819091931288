import React from "react";
import { choices, Toolbar, SaveButton } from "react-admin";

import { PersonRoles } from "./enums";

export const onlyText = (value) =>
  value && /[^a-zA-Z äáàëéèíìöóòúùñç]/i.test(value)
    ? "Este campo solo acepta texto"
    : undefined;

export const noFutureDate = (value) =>
  value && new Date(value) > new Date()
    ? "La fecha no puede ser mayor a la actual"
    : undefined;

export const noPreviousDate = (value) =>
  value && new Date(value) < new Date()
    ? "La fecha no puede ser mayor a la actual"
    : undefined;

export const validateSex = choices(["M", "F"], "Debe ser hombre o mujer");

export const getRiskLevel = (countries, value) => {
  if (countries && value)
    return (
      countries && countries.filter((country) => country.id === value)[0].riskId
    );
};
export const isRolCreditor = (value, values) => {
  if (!values.roles?.includes(PersonRoles.creditor)) {
    return "Requerido";
  }
  return [];
};

export const DisabledDeleteButton = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

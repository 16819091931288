import WorkIcon from "@material-ui/icons/Work";
import LegalPersonList from "./LegalPersonList";
import LegalPersonCreate from "./LegalPersonCreate";
import LegalPersonEdit from "./LegalPersonEdit";
import LegalPersonShow from "./LegalPersonShow";

export default {
  show: LegalPersonShow,
  list: LegalPersonList,
  create: LegalPersonCreate,
  edit: LegalPersonEdit,
  icon: WorkIcon,
};

import React from "react";
import { Edit, SimpleForm, TextInput, required, TopToolbar } from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";
const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const RiskLevelEdit = (props) => (
  <Edit title=" " actions={<Actions {...props} />} {...props}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput disabled source="id" fullWidth />
      <TextInput source="name" fullWidth validate={required()} />
    </SimpleForm>
  </Edit>
);

export default RiskLevelEdit;

import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
  ReferenceField,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import ProductCreate from "./ProductCreate";
import ProductEdit from "./ProductEdit";
import { FormGeneral } from "../../styles/FormGeneral";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput label="Buscar por nombre" source="name" resettable alwaysOn />
  </Filter>
);

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const ProductList = (props) => {
  const classes = FormGeneral();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/products");
  }, [history]);

  return (
    <Fragment>
      <List filters={<Filters />} actions={<Actions />} {...props}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField
            source="riskLevelId"
            reference="risk-levels"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      </List>
      <Route path="/products/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <ProductCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path="/products/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <ProductEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default ProductList;

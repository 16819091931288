import React from "react";
import { DateInput, LinearProgress } from "react-admin";

const HelperTextStatusInput = ({ record, helperTextLabel, ...rest }) => {
  if (!record) {
    return <LinearProgress />;
  }

  const dataStatus = () => {
    if (record[helperTextLabel] === "Vigente") {
      return "green";
    }
    if (record[helperTextLabel] === "Vencido") {
      return "red";
    }
    return null;
  };

  const isDataStatus = dataStatus();

  return (
    <>
      <DateInput
        data-color={isDataStatus}
        helperText={record[helperTextLabel]}
        {...rest}
      />
    </>
  );
};

export default HelperTextStatusInput;

import React from "react";
import {
  Create,
  SimpleForm,
  TopToolbar,
  TextInput,
  required,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const PersonRelationshipTypeCreate = (props) => (
  <Create title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm redirect={props.basePath} variant="outlined">
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="description" multiline rows="4" fullWidth />
    </SimpleForm>
  </Create>
);

export default PersonRelationshipTypeCreate;

import React from "react";
import {
  Edit,
  SimpleForm,
  TopToolbar,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisabledDeleteButton } from "../../utils/validations";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const DistributionChannelEdit = (props) => (
  <Edit title=" " {...props} actions={<Actions {...props} />}>
    <SimpleForm
      redirect={props.basePath}
      variant="outlined"
      toolbar={<DisabledDeleteButton />}
    >
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <ReferenceInput
        source="riskLevelId"
        reference="risk-levels"
        multiline
        rows="4"
        fullWidth
      >
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default DistributionChannelEdit;

import crudProvider from "ra-data-nestjsx-crud";
import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import omitBy from "lodash.omitby";
import { fetchUtils } from "react-admin";
import { stringify } from "querystring";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = crudProvider(process.env.REACT_APP_API_URL, httpClient);
const composeFilter = (paramsFilter) => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("||");

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      ops = CondOperator.CONTAINS;
    }

    if (field.startsWith("_") && field.includes(".")) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] };
  });
};

const composeQueryParams = (queryParams) => {
  return stringify(fetchUtils.flattenObject(queryParams));
};

const mergeEncodedQueries = (...encodedQueries) => {
  return encodedQueries.map((query) => query).join("&");
};

const countDiff = (o1, o2) => omitBy(o1, (v, k) => o2[k] === v);

const customDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    if (!params.filter["$or"]) {
      // fallback to the default implementation
      return dataProvider.getList(resource, params);
    }

    delete params.filter["$or"];

    const { page, perPage } = params.pagination;
    const { q: queryParams, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      or: composeFilter(filter),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${process.env.REACT_APP_API_URL}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },
  update: (resource, params) => {
    // no need to send all fields, only updated fields are enough
    const { id } = params;
    let data = countDiff(params.data, params.previousData);
    data = { ...data, id };

    return httpClient(
      `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
      }
    ).then(({ json }) => ({ data: json }));
  },
};

export default customDataProvider;

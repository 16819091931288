import React from "react";
import { useListContext } from "react-admin";
import { RaGrid } from "ra-compact-ui";
import {
  DatagridTextField,
  DatagridReferenceTextField,
} from "../../../components";

import ShowTitle from "../../../components/ShowTitle";

const PersonReferencesIterator = () => {
  const { ids, data, basePath } = useListContext();

  return (
    <>
      <RaGrid item xs={12}>
        <ShowTitle title="Referencias personales" />
      </RaGrid>
      {ids.map((id, index) => (
        <RaGrid key={index} container item xs={12}>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="referenceTypeId"
              reference="reference-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridReferenceTextField
              resource="people"
              source="relationshipId"
              reference="person-relationship-types"
              id={id}
              data={data}
              basePath={basePath}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="name"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="contactOfficer"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="phoneNumber"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="address"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="email"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="position"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="company"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="income"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="otherIncome"
              id={id}
              data={data}
            />
          </RaGrid>
          <RaGrid item xs={4}>
            <DatagridTextField
              resource="people"
              source="notes"
              id={id}
              data={data}
            />
          </RaGrid>
        </RaGrid>
      ))}
    </>
  );
};

PersonReferencesIterator.defaultProps = {
  addLabel: true,
  data: {},
  ids: [],
};

export default PersonReferencesIterator;
